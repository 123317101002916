<template>
  <div>
    <el-card class="box-card1">
      <div slot="header" class="clearfix1">
        <span>创建项目</span>
      </div>
<!--      文档翻译按钮-->
        <el-button @click="translateDocument" style="width: 327px;height: 106px">
          <el-card class="box-card11" style="margin-left: 6px">
            <div slot="header" class="clearfix">
              <span style="float: left;"><strong>文档翻译</strong></span>
            </div>
              <span style="display: block;line-height: 18px;text-align: left;margin-top: 3px">
                支持docx,xlsx,pptx,txt,xml等<br>文档格式的翻译</span>
          </el-card>
        </el-button>
<!--      图片翻译按钮-->
        <el-button @click="translatePhoto">
        <el-card class="box-card11" >
          <div slot="header" class="clearfix">
            <span style="float: left;"><strong>图片翻译</strong></span>
          </div>

            <span style="display: block;line-height: 18px;text-align: left;margin-top: 3px">智能可视化图片翻译,支持13个语向<br>、多种格式的图片的翻译工作,支持在线译文<br>图片格式调整</span>

        </el-card>
      </el-button>
<!--      视频翻译按钮-->
        <el-button @click="translateVideo">
        <el-card class="box-card11" >
          <div slot="header" class="clearfix">
            <span style="float: left;"><strong>视频翻译</strong></span>
          </div>
            <span style="display: block;line-height: 18px;text-align: left;margin-top: 3px">在线视频翻译工作台,智能语音识别、文本翻译、<br>时间轴调整、字幕生成</span>
        </el-card>
      </el-button>
    </el-card>
    <div class="box-card" style="margin-top: 10px;height: 321px">
      <el-card class="box-card2" style="width: 517px;margin-right: 15px;">
        <div slot="header" class="clearfix2" style="display: flex;justify-content:space-between">
          <span>我管理的项目</span>
          <router-link to="/project/mymanaproject">
            <el-link type="info">
              查看更多
              <i class="el-icon-arrow-right"></i>
            </el-link>
          </router-link>

        </div>
        <div>
          <el-table
              :data="tableData1"
              stripe
              style="width: 100%;">
            <el-table-column
                prop="projectName"
                label="项目名称"
                width="180">
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态"
                width="180">
            </el-table-column>
            <el-table-column
                prop="langDirection"
                label="语向">
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <el-card class="box-card3" style="width: 517px;">
        <div slot="header" class="clearfix3" style="display: flex;justify-content:space-between">
          <span>我参与的项目</span>
          <router-link to="/project/myproject">
          <el-link type="info">
              查看更多
              <i class="el-icon-arrow-right"></i>
          </el-link>
          </router-link>
        </div>
        <div>
          <el-table
              :data="tableData2"
              stripe
              style="width: 100%">
            <el-table-column
                prop="projectName"
                label="项目名称"
                width="180">
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态"
                width="180">
            </el-table-column>
            <el-table-column
                prop="langDirection"
                label="语向">
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data(){
    return{
      tableData1:[
        {
          projectName:"test",
          status:"进行中",
          langDirection:"中文-英文",
        }
      ],
      tableData2:[]
    }
  },
  created() {
    this.getProjectList()
  },
  methods:{
    //获取我管理的项目列表
    async getProjectList(){
      const {data:result} = await this.$axios.post("/project/projectlist",{
        currentPage:1,
        pageSize:4,
        projectName:"",
        origLang:"",
        targLang:"",
        status:"",
        label:"",
        username:sessionStorage.getItem("username")
      })
      console.log('result:',result)
      if(result.code!==200)
        return this.$message.error('获取项目列表失败')
      console.log(result.data.data.records,"aaaaaa")
      this.tableData1 = result.data.data.records;
    },
    translateDocument(){
      console.log("执行文档翻译方法")
    },
    translatePhoto(){
      console.log("执行图片翻译方法")
    },
    translateVideo(){
      this.$router.push('/mainpage/transvideo');
    },
    // viewMyManaProject(index){
    //   console.log(index)
    //   this.$store.commit('SET_INDEX',index)
    //   this.$router.push("/project/mymanaproject")
    // },
    // viewMyProject(index){
    //   console.log(index)
    //   this.$store.commit('SET_INDEX',index)
    //   this.$router.push("/project/myproject")
    // }
  },

}

</script>

<style>
.box-card1>.el-card__header{
  padding-bottom: 5px;
}
.box-card11{
  width: 315px;
  height: 100px;
}
.box-card11>.el-card__header{
  border-bottom: none;
}
.box-card11>.el-card__body{
  padding-top: 10px;
  margin-left: 0;
}
.box-card{
  display: flex;
}

div>.el-button{
  margin: 0;
  padding: 0;
  border: none;
}
.box-card1>.el-card__body{
  padding: 0 20px 0 10px;
}
.box-card2>.el-card__body{
  padding: 0 20px 0 10px;
}
.box-card3>.el-card__body{
  padding: 0 20px 0 10px;
}
</style>