<template>
  <div>
    <div style="margin-bottom: 5px">
      <span>项目看板</span>
    </div>
    <el-card style="display: flex;justify-content: center;text-align: center;align-items: center;margin-bottom: 6px">
      <el-row style="margin-top:20px ">
        <el-col :span="50" style="margin-right: 75px">
          进行中项目(个)
        </el-col>
        <el-col :span="50" style="margin-left: 150px">
          已超期项目(个)
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px;">
        <el-col :span="50" style="margin-left: 40px;">
          <strong>{{ progressCount }}</strong>
        </el-col>
        <el-col :span="50" style="margin-left: 290px">
          <strong>{{ outTimeCount }}</strong>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <div slot="header">
        <span>未交付项目</span>
      </div>
      <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%">
        <el-table-column
            prop="id"
            label="项目ID"
            width="125">
        </el-table-column>
        <!--          项目名称-->
        <el-table-column
            prop="name"
            label="项目名称"
            width="125">
        </el-table-column>
        <el-table-column
            prop="resource"
            label="来源"
            width="100">
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            width="100">
        </el-table-column>
        <el-table-column
            prop="progress"
            label="进度"
            width="100">
        </el-table-column>
        <el-table-column
            prop="language"
            label="语向"
            width="100">
        </el-table-column>
        <el-table-column
            label="原始字数/句对数">
          <template slot-scope="scope">
            {{ scope.row.wordNum }}/{{scope.row.sentNum}}</template>
        </el-table-column>
        <el-table-column
            prop="date"
            label="创建日期"
            width="100">
          <template slot-scope="scope">{{ scope.row.date }}</template>
        </el-table-column>
        <el-table-column
            label="操作"
            width="120">
          <template slot-scope="scope">
            <el-button @click="handleDistri(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">分配<i class="el-icon-arrow-down"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "ProjectWatcher",
  data(){
    return{
      //进行中项目个数
      progressCount:121,
      //超期项目个数
      outTimeCount:21,
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //表格所绑定的内容
      tableData:[
        {
          id:'20212121',
          name:"行列式的性质1",
          resource:"本人创建",
          status:"已完成",
          progress:"100%",
          language:"中文-英文",
          wordNum:"2100",
          sentNum:"210",
          date:"2021-11-15",
          operator:"翻译"},
        {
          id:'20212122',
          name:"行列式的性质2",
          resource:"本人创建",
          status:"已完成",
          progress:"100%",
          language:"中文-英文",
          wordNum:"2100",
          sentNum:"210",
          date:"2021-11-17",
          operator:"翻译"},
        {
          id:'20212123',
          name:"行列式的性质3",
          resource:"本人创建",
          status:"已完成",
          progress:"100%",
          language:"中文-英文",
          wordNum:"2100",
          sentNum:"210",
          date:"2021-11-16",
          operator:"翻译"},
      ],

    }
  },
  methods:{
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getVideoList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getVideoList()
    },
    //分配操作
    handleDistri(){

    }
  }
}
</script>

<style>
.el-card>.el-card__header{
  padding:20px 18px 6px 18px;
}
.el-card>.el-card__body{
  padding-top:6px;
}
.el-button + .el-button{
  padding: 2px 5px 2px 5px;
}
</style>