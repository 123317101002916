<template>
  <div >

    <!-- <header style="height: 60px">
      <span>客户端1验证:{{name}}</span>
        <button @click="logout">安全退出</button>
        <button @click="test">测试</button>
        <span>:{{title}}</span>
    </header>
    <router-view></router-view>

  <my-vue v-bind:lineID="lineID"></my-vue>--> 
  </div>
</template>
<style lang="scss">
</style>
<script type="text/ecmascript-6">

  export default {

    data() {
      return {
            name:'ss',
            title:''
      }
    },

created(){
    var _this = this;
    this.$http.get('/casAuth', {headers: {'x-requested-with': 'XMLHttpRequest'}})
            .then(function (response) {
                if (response.data.code === 202) {
                    //console.log(response);
                    window.location.href = response.data.url
                } else if (response.data.code === 200) {
                    _this.$store.commit('SET_UID',response.data.data.uid)
                    _this.$store.commit('SET_USERNAME',response.data.data.username)
                    _this.$store.commit('SET_TOKEN', response.data.data.token)
                    _this.$store.commit('SET_CN', response.data.data.loginName)
                    _this.$router.push("/home")
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
      methods: {
          logout() {
              window.location.href = "https://java1234.com:8443/cas/logout"
          },
          test(){
              this.$http.get("/cache")
                      .then((data)=>{
                  this.title = data.data.data.name
              }).catch(function (err) {
                  console.log(err);});
          }
      }
  }
</script>
