<template>
  <div>
    <div style="display: flex;justify-content: space-between;margin-bottom: 10px">
      <span>我的疑问</span>
    </div>
    <el-card>
      <div slot="header">
        <el-input placeholder="疑问描述" v-model="questionInput" style="width: 340px">
          <el-button slot="append" icon="el-icon-search" style="padding-left: 14px"></el-button>
        </el-input>
        <el-dropdown trigger="click" :hide-on-click="true">
        <span class="el-dropdown-link"
              style=" color: #5556E5;
                      margin-left: 14px">
              高级搜索
              <i class="el-icon-arrow-down el-icon--right"
                 style="margin-left: 2px"></i>
        </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item,index) in dropdownMenu" :key="index">{{ item }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div>
        <el-table
            border
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%">
          <!--          序列column-->
          <el-table-column
              type="index">
          </el-table-column>
          <!--          项目名称-->
          <el-table-column
              prop="descri"
              label="疑问描述">
          </el-table-column>
          <el-table-column
              prop="origText"
              label="原文">
          </el-table-column>
          <el-table-column
              prop="transText"
              label="译文">
          </el-table-column>
          <el-table-column
              prop="status"
              label="状态">
          </el-table-column>
          <el-table-column
              prop="modifiedUser"
              label="创建人">
          </el-table-column>
          <el-table-column
              prop="updateDate"
              label="更新日期">
          </el-table-column>
          <el-table-column
              prop="type"
              label="疑问类别">
          </el-table-column>
          <el-table-column
              prop="target"
              label="建议修订为">
          </el-table-column>
          <el-table-column
              prop="level"
              label="严重级别">
          </el-table-column>
          <el-table-column
              label="操作">
            <el-button @click="handleEdit(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">编辑</el-button>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Myquestions",
  data(){
    return{
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //疑问输入框
      questionInput:'',
      tableData:[
        {
          descri:'这是一个问题',
          origText:'hello,world',
          transText:'好，世界',
          status:'已完成',
          modifiedUser:'admin',
          updateDate:'2020-03-09',
          type:'1',
          target:'你好，世界',
          level:'2'
        },
      ],
    }
  },
  methods:{
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getVideoList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getVideoList()
    },
    handleEdit(){

    }
  }
}
</script>

<style scoped>

</style>