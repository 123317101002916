<template>
  <el-aside width="160px">
    <el-menu
        :uniqueOpened="true"
        :default-active="activeNavIndex"
        class="el-menu-vertical-demo"
        background-color="#fff"
        text-color="#000"
        @select="handleNavSelect"
        active-text-color="#ffd04b">
<!--首页导航栏-->
      <router-link to="/index">
        <el-menu-item index="0">
          <template #title>
            <span class="iconfont icon-shouye"></span>
            <span>&nbsp;&nbsp;首页</span>
          </template>
        </el-menu-item>
      </router-link>
<!--      首页导航栏结束-->
<!--      我的项目导航栏-->
      <el-submenu index="2">
        <template #title>
          <span class="iconfont icon-xiangmu_xiangmuguanli"></span>
          <span>&nbsp;&nbsp;我的项目</span>
        </template>
        <router-link to="/project/mymanaproject">
          <el-menu-item index="2-1">
            <template #title>
              <span>我管理的项目</span>
            </template>
          </el-menu-item>
        </router-link>
        <router-link to="/project/myproject">
          <el-menu-item index="2-2">
            <template #title>
              <span>我参与的项目</span>
            </template>
          </el-menu-item>
        </router-link>
<!--        <router-link to="/video/videolist">-->
<!--          <el-menu-item index="1-2">-->
<!--            <template #title>-->
<!--              <i class="el-icon-rank"></i>-->
<!--              <span>视频列表</span>-->
<!--            </template>-->
<!--          </el-menu-item>-->
<!--        </router-link>-->
      </el-submenu>
<!--      我的项目导航栏结束-->
<!--      语言资产导航栏-->
      <el-submenu index="3">
        <template #title>
          <span class="iconfont icon-zichanmingxi"></span>
          <span>&nbsp;&nbsp;语言资产</span>
        </template>
        <router-link to="/lang/langlib">
          <el-menu-item index="3-1">
            <template #title>
              <span>语料库管理</span>
            </template>
          </el-menu-item>
        </router-link>
        <router-link to="/lang/langlibsearch">
          <el-menu-item index="3-2">
            <template #title>
              <span>语料查询</span>
            </template>
          </el-menu-item>
        </router-link>
        <router-link to="/lang/majorlanglib">
        <el-menu-item index="3-3">
            <template #title>
              <span>术语库管理</span>
            </template>
          </el-menu-item>
        </router-link>
        <router-link to="/lang/majorlanglibsearch">
          <el-menu-item index="3-4">
            <template #title>
              <span>术语查询</span>
            </template>
          </el-menu-item>
        </router-link>
        <!--        <router-link to="/video/videolist">-->
        <!--          <el-menu-item index="1-2">-->
        <!--            <template #title>-->
        <!--              <i class="el-icon-rank"></i>-->
        <!--              <span>视频列表</span>-->
        <!--            </template>-->
        <!--          </el-menu-item>-->
        <!--        </router-link>-->
      </el-submenu>
<!--      语言资产导航栏结束-->
<!--      我的团队导航栏开始-->
      <el-submenu index="4">
        <template #title>
          <span class="iconfont icon-tuandui"></span>
          <span>&nbsp;&nbsp;我的团队</span>
        </template>
        <router-link to="/group/mygrouplist">
          <el-menu-item index="4-1">
            <template #title>
              <span>团队列表</span>
            </template>
          </el-menu-item>
        </router-link>
        <router-link to="/group/mygroupmember">
          <el-menu-item index="4-2">
            <template #title>
              <span>团队成员</span>
            </template>
          </el-menu-item>
        </router-link>
        <!--        <router-link to="/video/videolist">-->
        <!--          <el-menu-item index="1-2">-->
        <!--            <template #title>-->
        <!--              <i class="el-icon-rank"></i>-->
        <!--              <span>视频列表</span>-->
        <!--            </template>-->
        <!--          </el-menu-item>-->
        <!--        </router-link>-->
      </el-submenu>
<!--      我的图队导航栏结束-->
<!--      数据报表导航栏开始-->
      <el-submenu index="5">
        <template #title>
          <span class="iconfont icon-shujubaobiao"></span>
          <span>&nbsp;&nbsp;数据报表</span>
        </template>
        <router-link to="/data/projectwatcher">
          <el-menu-item index="5-1">
            <template #title>
              <span>项目看板</span>
            </template>
          </el-menu-item>
        </router-link>
        <router-link to="/data/projecttable">
          <el-menu-item index="5-2">
            <template #title>
              <span>项目报表</span>
            </template>
          </el-menu-item>
        </router-link>
        <router-link to="/data/projecttranstable">
          <el-menu-item index="5-3">
            <template #title>
              <span>译员报表</span>
            </template>
          </el-menu-item>
        </router-link>
        <router-link to="/data/projectchecktable">
          <el-menu-item index="5-4">
            <template #title>
              <span>审校报表</span>
            </template>
          </el-menu-item>
        </router-link>
        <!--        <router-link to="/video/videolist">-->
        <!--          <el-menu-item index="1-2">-->
        <!--            <template #title>-->
        <!--              <i class="el-icon-rank"></i>-->
        <!--              <span>视频列表</span>-->
        <!--            </template>-->
        <!--          </el-menu-item>-->
        <!--        </router-link>-->
      </el-submenu>
<!--      数据报表导航栏结束-->
<!--      工具箱导航栏-->
      <el-submenu index="6">
        <template #title>
          <span class="iconfont icon-gongjuxiang"></span>
          <span>&nbsp;&nbsp;工具箱</span>
        </template>
        <router-link to="/tools/checktools">
          <el-menu-item index="6-1">
            <template #title>

              <span>质检工具</span>
            </template>
          </el-menu-item>
        </router-link>
        <router-link to="/tools/langtools">
          <el-menu-item index="6-2">
            <template #title>
              <span>双语对齐</span>
            </template>
          </el-menu-item>
        </router-link>
        <router-link to="/tools/majorlangtools">
          <el-menu-item index="6-3">
            <template #title>
              <span>术语提取</span>
            </template>
          </el-menu-item>
        </router-link>
      </el-submenu>
<!--      工具箱导航栏结束-->
<!--      疑问导航栏-->
      <router-link to="/questions/myquestions">
        <el-menu-item index="7">
          <template #title>
            <span class="iconfont icon-yiwen"></span>
            <span>&nbsp;&nbsp;疑问</span>
          </template>
        </el-menu-item>
      </router-link>
<!--      <router-link to="/settings">-->
<!--        <el-menu-item index="8">-->
<!--          <template #title>-->
<!--            <span class="iconfont icon-shezhi"></span>-->
<!--            <span>&nbsp;&nbsp;设置</span>-->
<!--          </template>-->
<!--        </el-menu-item>-->
<!--      </router-link>-->
<!--      视频处理功能-->
<!--      疑问导航栏结束-->
<!--      视频处理导航栏-->
<!--      <el-submenu index="1">-->
<!--        <template #title>-->
<!--          <i class="el-icon-menu"></i>-->
<!--          <span>视频管理</span>-->
<!--        </template>-->
<!--        <router-link to="/video/videoproc">-->
<!--          <el-menu-item index="1-1">-->
<!--            <template #title>-->
<!--              <i class="el-icon-s-operation"></i>-->
<!--              <span>处理视频</span>-->
<!--            </template>-->
<!--          </el-menu-item>-->
<!--        </router-link>-->
<!--        <router-link to="/video/videolist">-->
<!--          <el-menu-item index="1-2">-->
<!--            <template #title>-->
<!--              <i class="el-icon-rank"></i>-->
<!--              <span>视频列表</span>-->
<!--            </template>-->
<!--          </el-menu-item>-->
<!--        </router-link>-->
<!--      </el-submenu>-->
<!--          视频处理导航栏结束-->
<!--      个人中心导航栏-->
<!--      <el-submenu index="8">-->
<!--        <template #title>-->
<!--          <i class="el-icon-s-custom"></i>-->
<!--          <span>个人中心</span>-->
<!--        </template>-->
<!--        <router-link to="/user/userinfo">-->
<!--          <el-menu-item index="8-1">-->
<!--            <template #title>-->
<!--              <i class="el-icon-s-operation"></i>-->
<!--              <span>修改密码</span>-->
<!--            </template>-->
<!--          </el-menu-item>-->
<!--        </router-link>-->
<!--      </el-submenu>-->
<!--      个人中心导航栏结束-->
    </el-menu>
  </el-aside>
</template>

<script>
export default {
  name: "SideMenu",
  data(){
    return{
      activeNavIndex:'0',
    }
  },
  created() {
    // console.log(sessionStorage.getItem("sideActiveIndex"),'1111111')
    // console.log(this.$store.getters.GET_INDEX,'aaaaaaa')
    //使用默认活跃的index
    console.log(this.activeNavIndex,"默认激活index")
    console.log(this.$store.getters.GET_INDEX,"刷新激活")
    this.activeNavIndex = this.$store.getters.GET_INDEX === null ? "0" : this.$store.getters.GET_INDEX
  },
  methods:{
    handleNavSelect(index){
      this.$store.commit('SET_INDEX',index)
      this.activeNavIndex = index
    }
  }
}
</script>

<style>
.iconfont{
  padding-top: 2px;
}
.el-submenu__title>.el-submenu__icon-arrow{
  margin-top: -3px;
}
.el-menu-vertical-demo {
  height: 100vh;
}
</style>