<template>
  <el-container>
    <el-header style="height: 44px">
      <div>
        <el-image :src="require('@/assets/xidian.jpg')" alt=""
                  fit="contain"></el-image>
      </div>
      <strong style="padding-right: 366px">欢迎使用XDMOOC智能处理系统，请先登录！</strong>
    </el-header>
<!--    <div style="padding-bottom: 0;height: 1px">-->
<!--      <el-divider></el-divider>-->
<!--    </div>-->
    <el-container>
      <el-main style="padding: 0">
        <div class="main" style="position: relative;width: 1230px;height: 500px;margin-bottom: 10px;padding: 0">
          <div class="lunboClass"
               style="position: absolute;left: 0;top: 0;width: 1263px;height: 500px;z-index: 60;padding: 0">
            <MyCarousel>
            </MyCarousel>
          </div>
          <div class="loginClass" style="color: red;position: absolute;top:60px;left: 700px;z-index:70">
            <el-card class="loginCard" style="width: 340px;height: 378px;padding: 22px 38px 22px 38px;text-align: center;">
              <div slot="header" class="loginMenu" style="padding: 0px;border-bottom: none">
                <el-menu :default-active="activeIndex"
                         class="settingMenu"
                         active-text-color="#5556e5"
                         mode="horizontal" @select="handleSelect">
                  <el-menu-item index="1" style="width: 152px">账号登录</el-menu-item>
                  <el-menu-item index="2" style="width: 152px">手机号登录</el-menu-item>
                </el-menu>
              </div>
              <div v-if="activeIndex==='1'">
                <el-form :model="loginForm" :rules="rules" ref="loginForm" class="demo-loginForm" style="width: 304px">
                <el-form-item  prop="username" class="loginItem1" >
                  <el-input  placeholder="用户名为学号/工资号"
                             prefix-icon="el-icon-user"
                             v-model="loginForm.username"></el-input>
                </el-form-item>
                <el-form-item  prop="password" class="loginItem2">
                  <el-input placeholder="请输入密码"
                            prefix-icon="el-icon-unlock"
                            v-model="loginForm.password" type="password"></el-input>
                </el-form-item>
                <el-form-item class="loginItem3">
                  <el-button type="primary" @click="submitForm('loginForm')">登录</el-button>
                </el-form-item>
              </el-form>
              </div>
              <div v-else>
                <el-form :model="phoneForm" :rules="phoneRules" ref="phoneForm" style="width: 304px">
                  <el-form-item  prop="username" class="loginItem1" >
                    <el-input  placeholder="请输入手机号"
                               prefix-icon="el-icon-user"
                               v-model="phoneForm.username"></el-input>
                  </el-form-item>
                  <el-form-item  prop="password" class="loginItem2">
                    <el-input placeholder="请输入密码"
                              prefix-icon="el-icon-unlock"
                              v-model="phoneForm.password" type="password"></el-input>
                  </el-form-item>
                  <el-form-item class="loginItem3">
                    <el-button type="primary" @click="submitForm('phoneForm')">登录</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-card>
          </div>
        </div>

      </el-main>
      <el-footer class="bottom-container">
          <div  style="margin-left: 10%;">
            <h3>温馨提示</h3>
            <p>1.学生登录帐号为学号，例如：18010281234；教职工登录帐号为工资号，例如：6403</p>
            <p>2.建议浏览器： IE10+, 谷歌, 360极速</p>
          </div>
          <div class="auth_login_footer" style="text-align: center">
            <span>
            版权信息：Copyright&nbsp;©&nbsp;西安电子科技大学版权所有&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            地址：西安市雁塔区太白南路2号/长安区西沣路266号 &nbsp;&nbsp;
            </span>
          </div>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import qs from 'qs';
import MyCarousel from "@/components/MyCarousel";
export default {
  name: "Login",
  components: {
    MyCarousel
  },
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      },
      phoneForm: {
        username: '',
        password: '',
        token: ''
      },
      phoneRules: {
        username: [
          {required: true, message: '请输入手机号',trigger:'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
      },
      activeIndex:'1',
      userInfo: {
        id: "",
        username: "",
        avatar: ""
      }
    }
  },
  methods: {
    handleSelect(index){
      this.activeIndex = index
    },
     submitForm(formName) {
       this.$refs[formName].validate(async(valid) => {
        if (valid) {
          const {data:result} = await this.$axios.post('/user/login',this.loginForm)
          if(result.code !==200)
            return this.$message.error(result.message)
          const uid = result.data.uid
          const username = result.data.username
          const token = result.data.token
          this.$store.commit('SET_UID',uid)
          this.$store.commit('SET_USERNAME',username)
          this.$store.commit('SET_TOKEN', token)
          this.$router.push("/home")
              //   // const jwt = res.headers['authorization']
              //   // this.$store.commit('SET_TOKEN', jwt)
              //   this.$store.commit('SET_INDEX',0)
              //   console.log("登陆中.................")
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style >
.el-image{
  width:200px;
  height: 50px;
  margin-top: 21px;
  text-align: center;
}

.header-avatar {
  float: right;
  width: 110px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.el-container {
  margin: 0;
  padding: 0;
  /*//height: 100vh;*/
}

.el-header {
  display: flex;
  background-color: #fff;
  color: #000;
  text-align: center;
  line-height: 60px;
  justify-content: space-between;
  align-items: center;
}
.el-main {
  background-color: #fff;
  color: #000;
  padding: 0;
}

a {
  text-decoration: none;
}
.el-divider--horizontal{
  margin: 0;
  padding: 0;
  font-size: 0;
}
/*用户名框*/
.loginItem1>.el-form-item__content{
  width: 304px;
  margin-bottom: 22px;
}
/*密码框*/
.loginItem2>.el-form-item__content{
  width: 304px;

}
/*登录框*/
.loginItem3>.el-form-item__content{
  width: 304px;
  padding: 0;
}
.loginItem3>.el-form-item__content>.el-button{
  width: 304px;
  margin-top: 22px;
  padding: 13px 0px 13px 0px;
  background-color: #5556E5;
  color:white;

}
/*导航栏框*/
.loginCard>.el-card__header{
  border-bottom: none;
}
</style>