<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path:'/group/mygrouplist' }">团队列表</el-breadcrumb-item>
      <el-breadcrumb-item>创建团队</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box" style="margin-top: 10px;" v-if="showFlag">
      <div slot="header" class="clearfix1" style="padding: 0px">
        <span>创建团队</span>
      </div>
      <el-form :model="libForm"  ref="libFormRef" :show-message="false" class="libFormClass">
        <el-form-item label="团队名称" prop="name" required label-position="left" label-width="100px" class="groupNameClass">
          <el-input style="width: 300px" v-model="libForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="领域" style="margin-left: 0" required label-position="left" label-width="100px" prop="field">
          <el-select  style="width: 300px" v-model="libForm.field" placeholder="请选择" >
            <el-option v-for="(item,index) in this.fieldOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="语向选择"
                      style="margin-left: 0;"
                      required label-position="left" label-width="100px" class="Lang">
          <div style="display: flex;justify-content: space-between">
            <el-form-item prop="origLang" class="origLangClass" required>
              <el-select v-model="libForm.origLang" placeholder="请选择源语言">
                <el-option v-for="(item,index) in this.origLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="targLang" class="origLangClass" required>
              <el-select v-model="libForm.targLang" placeholder="请选择目标语言">
                <el-option v-for="(item,index) in this.targLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form-item>
<!--        <el-form-item label="团队描述" prop="description"  label-position="left" label-width="100px">-->
<!--          <el-input style="width: 300px" v-model="libForm.description" placeholder="请输入"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item style="margin-left: 80px">
          <el-button
              @click="submitForm1('libFormRef')"
              type="primary" plain
              style="margin-left: 100px;color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
          >
            下一步</el-button>
          <el-button
              @click="resetForm('libFormRef')"
              type="primary" plain
              style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid">
            取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card v-else class="box" style="margin-top: 10px;">
      <div slot="header">
        <span>添加成员</span>
      </div>
      <div style="display: flex;justify-content: space-between">
        <span style="height: 30px;padding-top: 10px">已选译员【{{transCount}}】</span>
        <el-form  :model="groupForm" :inline="true" style="margin-bottom: 10px;">
          <el-form-item label="账号名">
            <el-input v-model="groupForm.loginName"  placeholder="请输入账号名"></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="groupForm.name"  placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item class="formItem4">
            <el-button
                type="primary" plain
                style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
            >
              搜索</el-button>
            <el-button
                type="primary" plain
                style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
            >
              重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table
            border
            ref="multipleTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
            style="width: 100%">
          <el-table-column
              type="selection">
          </el-table-column>
          <!--          项目名称-->
          <el-table-column
              prop="loginName"
              label="账号名">
          </el-table-column>
          <el-table-column
              prop="username"
              label="姓名">
          </el-table-column>
          <el-table-column
              label="角色">
            <template slot-scope="scope">
              <el-tag plain>
                {{scope.row.role}}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
              prop="email"
              label="电子邮箱"
          >
          </el-table-column>
          <el-table-column
              prop="phone"
              label="手机号码"
          >
          </el-table-column>
<!--          <el-table-column-->
<!--              prop="langDirection"-->
<!--              label="语向">-->
<!--          </el-table-column>-->
        </el-table>
      </div>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
      <el-button
          @click="goBack"
          type="primary" plain
          style="margin-left: 670px;color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
      >
        上一步</el-button>
      <el-button
          @click="submitForm()"
          type="primary" plain
          style="margin-left: 30px;color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
      >
        完成创建</el-button>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "NewGroup",
  data() {
    return{
      //保存选中的rows
      val:[],
      count:0,
      //paramInfo 待传输给后台的数据
      paramInfo:{
        groupName:'',
        field:'',
        origLang:'',
        targLang:'',
      },
      //第三页中已选译员的数量
      transCount:0,
      //控制第二页和第三页的展示 默认展示第二页
      showFlag:true,
      //fieldOption 领域的选项
      fieldOption:[
        {
          label:'人工智能',
          value:'人工智能'
        },
        {
          label:'计算机',
          value:'计算机'
        },
      ],
      origLangOption:[
        {
          label:'中文',
          value:'中文'
        },
        {
          label:'英文',
          value:'英文'
        },
      ],
      targLangOption:[
        {
          label:'中文',
          value:'中文'
        },
        {
          label:'英文',
          value:'英文'
        },
      ],
      //新建语料库表单
      libForm:{
        name:'',
        field:'',
        origLang:'',
        targLang:'',
        //description:'',
      },
      //groupForm
      groupForm:{
        loginName:'',
        name:'',
      },
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //表格所绑定的内容
      tableData:[
        // {
        //   loginName:'xidian1234',
        //   name:"张三丰",
        //   role:[
        //     {
        //       type:'',label:'译员'
        //     },
        //     {
        //       type:'success',label:'项目管理员'
        //     },
        //   ],
        //   password:"123456",
        //   email:"123456@qq.com",
        //   phone:'15868833333',
        //   language:"中文-英文",
        //
        // },
        // {
        //   loginName:'xidian2234',
        //   name:"张二丰",
        //   role:[
        //     {
        //       type:'',label:'译员'
        //     },
        //   ],
        //   password:"345678",
        //   email:"654321@qq.com",
        //   phone:'15868822222',
        //   language:"英文-中文",
        // },
      ],
      //checkbox复选框按钮
      multipleSelection: []
    }
  },
  methods: {
    //返回上一页
    goBack(){
      this.showFlag = true
    },
    //将二页的数据暂存于发送区
    submitForm1(formName) {
      this.count = this.count+1
      console.log("aaaaaaaaaa",this.val)
      if(this.count===1)
        this.getGroupMemberList()
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.paramInfo.groupName =this.libForm.name
          this.paramInfo.field  = this.libForm.field
          this.paramInfo.origLang = this.libForm.origLang
          this.paramInfo.targLang = this.libForm.targLang
          //显示下一页
          this.showFlag = false
          //请求下一页的数据
        } else {
          this.$message.error("请输入必选项");
          return false;
        }
      });
    },
    handleSelectionChange(val) {
      this.val = val
      this.multipleSelection = val
      this.transCount = this.multipleSelection.length
      this.disabled = this.multipleSelection == 0
    },
    setSelection(rows){
      if (rows) {
        rows.forEach(row => {
          console.log("执行中")
          console.log(row)
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //发起提交二页 三页表单请求
    async submitForm() {
      // if (rows) {
      //   rows.forEach(row => {
      //     console.log("执行中")
      //     console.log(row)
      //     this.$refs.multipleTable.toggleRowSelection(row);
      //   });
      // } else {
      //   this.$refs.multipleTable.clearSelection();
      // }


      const uids = []
      this.multipleSelection.forEach((item)=>{
        uids.push(item.uid)
      })
      const {data:result} = await this.$axios.post("/group/creategroup",
          {
           groupName:this.paramInfo.groupName,
           field:this.paramInfo.field,
           origLang:this.paramInfo.origLang,
           targLang:this.paramInfo.targLang,
            uids:uids,
            uid:this.$store.getters.GET_UID
          })
      console.log('result:',result.data.data)
      if(result.code!==200)
        return this.$message.error('创建团队失败')
      this.$message.success("创建团队成功,即将返回。")
      setTimeout(()=>{
        this.$router.push("/group/mygrouplist")
      },1500)
    },
    resetForm(formNameRef) {
      this.$refs[formNameRef].resetFields();
      this.$router.push("/group/mygrouplist")
    },
    //根据当前登录用户获取所在团队列表
    async getCurrentGroupList(){
      const {data:result} = await this.$axios.post('/group/currentgrouplist')
      console.log("Aaaaaaaaaaaaaaaaaa")
      if(result.code!==200)
        return this.$message.error('获取团队列表失败')
    },
    //获得用户（团队成员列表）
    getGroupMemberList(){
      this.getCurrentGroupList().then(async ()=>{
        const {data:result} =   await this.$axios.post("/group/groupmember",{
          currentPage:this.currentPage,
          pageSize:this.size,
          loginName:this.groupForm.loginName,
          username:this.groupForm.name,
          uid:this.$store.getters.GET_UID
        })
        console.log('result:',result.data.data)
        if(result.code!==200)
          return this.$message.error('获取项目列表失败')
        this.tableData = result.data.data;
        this.total = result.data.total
      })
    },
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getGroupMemberList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getGroupMemberList()
    },
  },

}
</script>

<style>
.box>.el-card__header{
  padding: 10px 20px 10px 20px;
}
.box>.el-card__body{
  height: 380px;
  padding-top: 10px;
}
</style>