<template>
  <div>
    <el-card>
      <el-form  :model="toolsForm" :inline="true" style="margin-bottom: 6px;">
        <el-form-item label="状态">
          <el-select v-model="toolsForm.status"  placeholder="请选择">
            <el-option v-for="(item,index) in this.statusOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-button
              type="primary" plain
              style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid">
            搜索</el-button>
          <el-button
              type="primary" plain
              style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid">
            重置</el-button>
        </el-form-item>
<!--        刷新按钮-->
        <el-form-item style="float: right;width: 42px">
          <el-button  type="primary" plain
                      style="color: #5556E5;padding: 7px 15px 7px 15px"
                      icon="el-icon-refresh-right"></el-button>
        </el-form-item>
      </el-form>
      <div>
        <router-link to="/tools/qualitycheck">
          <el-button
              type="primary" plain
              style="margin-bottom: 6px;float: right;color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid">
            质量检查</el-button>
        </router-link>
      </div>
      <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;margin-top: 10px;">
        <!--          项目名称-->
        <el-table-column
            prop="name"
            label="名称">
        </el-table-column>
        <el-table-column
            prop="type"
            label="类别">
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态">
        </el-table-column>
        <el-table-column
            prop="modifiedTime"
            label="创建日期">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button @click="handleMore(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">查看详情<i class="el-icon-arrow-down"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "CheckTools",
  data(){
    return{
      //审校工具表单
      toolsForm:{
        status:'',
      },
      //状态多选框
      statusOption:[
        {
          label:'已完成',
          value:'已完成'
        },
        {
          label:'进行中',
          value:'进行中'
        },
        {
          label:'未翻译',
          value:'未翻译'
        },
      ],
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //表格数据
      tableData: [{
        name:'西电简介',
        type:'项目(123888)',
        status:'已完成',
        modifiedTime:'2021-11-16',
      },
      ]
    }
  },
  methods:{
    handleMore(){

    }
  }
}
</script>

<style scoped>

</style>