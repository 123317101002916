<template>
  <div>
    <div>
      <el-menu  style="height: 50px" :default-active="activeIndex" class="settingMenu" mode="horizontal" @select="handleSelect">
        <el-menu-item style="height: 50px" index="1">未完成工作量</el-menu-item>
        <el-menu-item style="height: 50px" index="2">译员工作表</el-menu-item>
      </el-menu>
    </div>
    <el-card v-if="activeIndex===`1`">
      <el-form  :model="memberForm" :inline="true" style="background-color:#fafafa;">
        <el-form-item label="译员" style="margin-left: 0;">
          <el-select v-model="memberForm.transMember" placeholder="请选择">
            <el-option v-for="(item,index) in this.memberOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="源语言" style="margin-left: 0">
          <el-select v-model="memberForm.origLang" placeholder="请选择">
            <el-option v-for="(item,index) in this.origLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目标语言" style="margin-left: 0">
          <el-select v-model="memberForm.targLang" placeholder="请选择">
            <el-option v-for="(item,index) in this.targLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item >
          <el-col :span="11">
            <el-date-picker class="date1" type="date" placeholder="起始日期" v-model="memberForm.date1" style="width: 100%;"></el-date-picker>
          </el-col>
          </el-form-item>
          <el-form-item >
          <el-col :span="11">
            <el-date-picker class="date2" type="date" placeholder="结束日期" v-model="memberForm.date2" style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>
      <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;margin-top: 10px;">
        <!--          项目名称-->
        <el-table-column
            prop="transMember"
            label="译员">
        </el-table-column>
        <el-table-column
            prop="progress"
            label="进度">
        </el-table-column>
        <el-table-column
            prop="type"
            label="生产类型">
        </el-table-column>
        <el-table-column>
            <template slot="header" slot-scope="scope">
              <span style="padding-left:250px ">字数</span>
            </template>
          <el-table-column
              prop="counted"
              label="已提交">
          </el-table-column>
          <el-table-column
              prop="counting"
              label="工作中">
          </el-table-column>
          <el-table-column
              prop="counter"
              label="未翻译">
          </el-table-column>
          <el-table-column
              prop="count"
              label="总字数">
          </el-table-column>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
    <el-card v-else>
      译员工作表页面
    </el-card>
  </div>
</template>

<script>
export default {
  name: "ProjectTransTable",
  data(){
    return{
      //源语言选项
      origLangOption:[
        {
          value:'英文',
          label:'英文'
        },
        {
          value:'中文',
          label:'中文'
        },
      ],
      //目标语言选项
      targLangOption:[
        {
          value:'英文',
          label:'英文'
        },
        {
          value:'中文',
          label:'中文'
        },
      ],
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //表格数据
      tableData: [{
        transMember: '译员1',
        type:'翻译',
        progress:"100%",
        count:2097,
        counted:'2000',
        counting:'97',
        counter:'0',
      },
      ],
      memberOption:[
        {
          label:'译员1',
          value:'译员1'
        },
        {
          label:'译员2',
          value:'译员2'
        },
      ],
      //自定义设置默认活跃的index
      activeIndex:'1',
      memberForm:{
        transMember:'',
        origLang:'',
        targLang:'',
        date1:'',
        date2:'',
      },
    }
  },
  methods:{
    handleView(row){
      console.log(row,"执行了handleView")
    },
    handleEdit(row){
      console.log(row,"执行了handleEdit")
    },
    handleSelect(index){
      this.activeIndex = index
      // console.log(this.activeIndex)
    },
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getVideoList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getVideoList()
    },
  }
}
</script>

<style>

</style>