<template>
  <div>
    <div>
      <el-alert
          class="warningAlert"
          title="请严格按照以下流程来处理视频，否则可能会出现未知错误影响视频处理。"
          type="warning"
          center
          show-icon
          :closable="false">
      </el-alert>
    </div>
      <el-row>
        <el-col class="step">
          <el-steps :active="active" finish-status="success">
            <el-step title="步骤 1" description="上传视频"></el-step>
            <el-step title="步骤 2" description="处理视频"></el-step>
            <el-step title="步骤 3" description="播放视频或重新处理视频"></el-step>
          </el-steps>
        </el-col>
      </el-row>

    <div>
    <el-upload
        :on-success="handleVodUploadSuccess"
        :show-file-list="false"
        :on-change="changeFile"
        :file-list="fileList"
        action="http://localhost:8003/eduvod/video/uploadVideoOss"

        class="elupload">
      <el-button class="upload" size="medium" type="primary" :disabled="disabled0">上传视频</el-button>
    </el-upload>

    <el-button class="proc" size="medium" type="primary" :disabled="disabled1" @click="getTaskId()">

      处理视频
    </el-button>
    <!--    <el-button class="proc" size="medium" type="primary"  @click="getVodVideoUrl()">-->

    <!--      处理视频2</el-button>-->
    <router-link :to="{path:'/player/playvideo',query:{playaddress:video.vodVideoUrl}}" target="_blank">
      <el-button class="play" size="medium" type="primary" :disabled="disabled2">播放视频</el-button>
    </router-link>
    <el-button class="cont" size="medium" type="primary" :disabled="disabled2" @click="continueProc()">
      继续处理视频
    </el-button>
    <div class="playvideo">
      <div class="prism-player" id="player-con"></div>
    </div>

  </div>
  </div>


</template>

<script>
export default {
  name: "Videoproc",
  data() {

    return {
      active:1,
      disabled0: false,
      disabled1: true,
      disabled2: true,

      taskIdStatus: false,
      loadProgress: 0,
      progressFlag: false,
      video: {
        videoSourceId: '',
        videoPlayAuth: '',
        videoPlayAddress: '',
        videoKey: "test1/test2.mp4",
        videoTitle: '',
        saveObjectPath: "test1",
        taskId: "34c4ecad-b28d-4cd1-a228-d7b8cc19a120",
        vodVideoUrl: "https://video.ailemon.net/sv/4397915f-17b7e616a41/4397915f-17b7e616a41.mp4"
      },
      videolist: [],
      fileList: [],
      files: ''
    }
  },
  methods: {
    continueProc() {
      this.disabled0 = false
      this.disabled1 = true
      this.disabled2 = true
    },
    changeFile(file, fileList) {

      this.files = fileList[0].raw

      if (file.status === 'ready') {
        let loading = this.$loading({
          lock: true,
          text: "正在上传中，请耐心等待。",
          background: 'rgba(0,0,0,0.7)'
        })
      }
    },
    // if(file.status === 'ready'){
    //   this.progressFlag = true; // 显示进度条
    //   this.loadProgress = 0;
    //   const interval = setInterval(() => {
    //     if(this.loadProgress >=99){
    //       clearInterval(interval)
    //       return
    //     }
    //     this.loadProgress += 1
    //
    //   }, 20);
    // }

    handleVodUploadSuccess(response, file) {
      //this.video.videoSourceId = response.data.videoId
      this.video.videoKey = response.data.videoKey
      this.video.saveObjectPath = response.data.saveObjectPath
      this.video.videoTitle = response.data.videoTitle
      console.log("获取videokey成功")
      console.log(this.video.videoKey)
      const _this = this
      let fl = new FormData()
      fl.append('file', this.files)
      this.$axios.post('/eduvod/video/uploadVideoVod', fl)
          .then(response => {
            this.video.videoSourceId = response.data.data.videoSourceId
            console.log(this.video.videoSourceId)
            let loading = this.$loading({
              lock: true,
              text: "正在上传中，请耐心等待。",
              background: 'rgba(0,0,0,0.7)'
            })
            loading.close()
            this.$message({
              showClose: true,
              message: '恭喜你，上传成功',
              type: 'success',
            })
            console.log("上传成功")
            this.disabled0 = true
            this.disabled1 = false
            this.active = !this.disabled1

          })
          .catch(error => {
            console.log("上传失败")
            this.$message.error('上传失败')
          })
    },
    getTaskId() {
      this.$axios.post('/eduvod/video/getTaskId', {
        videoKey: this.video.videoKey,
        saveObjectPath: this.video.saveObjectPath
      })
          .then(response => {
            let loading = this.$loading({
              lock: true,
              text: "正在处理中，请耐心等待。",
              background: 'rgba(0,0,0,0.7)'
            })
            this.video.taskId = response.data.data.taskId
            console.log("获取id成功", this.video)
            this.getVodVideoUrl()
            //console.log("执行getVodVideoUrl方法")
          })//成功时执行
          .catch(error => {
            alert("获取ID失败")
          })//失败时执行
    },
    updateVideo() {
      this.$axios.post('/eduvod/video/updatevideo', {
        videoSourceId: this.video.videoSourceId,
        vodVideoUrl: this.video.vodVideoUrl
      })
          .then(response => {
            console.log('id:', this.video.videoSourceId)
            console.log(this.video.vodVideoUrl)
            console.log("修改视频描述成功")
          })
          .catch(err => {
            console.log("修改失败")
          })
    },
    getVodVideoUrl() {
      console.log("video", this.video)
      this.$axios.post('/eduvod/video/getVodVideoUrl', {taskId: this.video.taskId})
          .then(response => {
            console.log("执行getVodVideoUrl方法成功")
            console.log(response.data.data.vodVideoUrl)
            this.video.vodVideoUrl = response.data.data.vodVideoUrl
            console.log("执行getVodVideoUrl方法成功")
            this.updateVideo()


            let loading = this.$loading({
              lock: true,
              text: "正在处理中，请耐心等待。",
              background: 'rgba(0,0,0,0.7)'
            })
            loading.close()
            alert("处理成功")
            this.disabled1 = true
            this.disabled2 = false
            this.active = !this.disabled2
          })//成功时执行
          .catch(error => {
            alert("处理失败，请稍后重试")
            this.disabled0 = false
            this.disabled1 = true
            this.active = !this.disabled0
          })//失败时执行
    },
    playButton() {
      var player = new Aliplayer({
        "id": "player-con",
        //"source":"https://video.ailemon.net/sv/33b7013f-17b0b06aa12/33b7013f-17b0b06aa12.mp4",
        "source": this.video.vodVideoUrl,
        //"source":"https://ailemon-storage.oss-cn-shanghai.aliyuncs.com/test1/e9d12aea-0985-4dd6-a076-279be0304042_output.mp4",
        //"source": "https://outin-946fd2d1dd5f11eb8e9300163e0eb78b.oss-cn-beijing.aliyuncs.com/sv/2a3fe8c2-17a7c45ecbd/2a3fe8c2-17a7c45ecbd.mp4?Expires=1625586308&OSSAccessKeyId=LTAI4FocoL6tuCdYhuvug6Ee&Signature=r1LOKTaHufQh71JBtLKDP5Aidwc%3D",
        //"source":"https://my-mooc.oss-cn-beijing.aliyuncs.com/wx/test/test.mp4?Expires=1627453373&OSSAccessKeyId=TMP.3Khg4SxX2TcucJhcxCe7Ept755PE39J7aQ8HADD2F5eVpHJKU4DjRTCD85VjJaHmT1QccZgmrHHpnEtPs97Ue2SzBqT5FX&Signature=dbx%2Bez8YlStBs7f1D86QkJU2DP4%3D",
        //"vid":this.video.videoSourceId,
        "playAuth": this.video.videoPlayAuth,
        "width": "100%",
        "height": "500px",
        "autoplay": false,
        "isLive": false,
        "rePlay": false,
        "playsinline": true,
        "preload": true,
        "controlBarVisibility": "hover",
        "useH5Prism": true
      }, function (player) {
        console.log("The player is created");
      })
      // this.disabled2 = true
      // this.disabled0 = false
      this.videolist.push(this.video)
      console.log(this.videolist.length)
    },
  }
}
</script>

<style lang="css" scoped>
.elupload {
  display: inline-block;
}

.proc {
  line-height: 210px;
  width: 210px;
  font-size: 32px;
  margin-left: 80px;
  margin-right: 80px;
}

.upload {
  line-height: 210px;
  width: 210px;
  font-size: 32px;
  margin-left: 0;
  margin-right: 10px;
}

.play {
  line-height: 210px;
  width: 210px;
  font-size: 32px;
  margin-left: 10px;
}

.cont {
  line-height: 210px;
  width: 220px;
  font-size: 32px;
  margin-left: 10px;
}
.warningAlert{
  height: 42px;
}

</style>