<template>
  <div>
    <el-form :inline="true" class="searchForm">
      <el-form-item>
        <el-input v-model="searchForm.title" placeholder="视频名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchByTitle(searchForm.title)">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-popconfirm title="确定删除？" @confirm="delButton(null)">
          <el-button type="danger" slot="reference" :disabled="disabled">批量删除</el-button>
        </el-popconfirm>
      </el-form-item>
    </el-form>
    <div class="table">
      <el-table
          ref="multipleTable"
          :data="tableData"
          border

          stripe
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>

        <el-table-column

            prop="coverURL"
            label="视频封面"
            width="120">

          <template slot-scope="scope">

            <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.coverURL"
            ></el-image>

          </template>

        </el-table-column>


        <el-table-column
            prop="title"
            label="视频名称"
            width="120">
        </el-table-column>
        <!--      <el-table-column-->
        <!--          prop="videoId"-->
        <!--          label="视频名称"-->
        <!--          width="120">-->
        <!--      </el-table-column>-->

        <el-table-column
            prop="createTime"
            label="创建日期"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="play"
            label="处理前视频"
            width="120">
          <template slot-scope="scope">
            <el-popconfirm title="确定播放" @confirm="playvideo(playURL)">
              <el-button type="text" slot="reference" @click="getPlayAddress(scope.row.videoId)">播放</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
        <el-table-column
            prop="play"
            label="处理后视频"
            width="120">
          <template slot-scope="scope">
            <el-popconfirm title="确定播放" @confirm="playvideo(playURL)">
              <el-button type="text" slot="reference" @click="getDescription(scope.row.videoId)">播放</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
        <el-table-column
            prop="delete"
            label="删除视频"
            width="120">
          <!--        <template slot-scope="scope">-->
          <!--          <el-button type="text" slot="reference" @click="delButton(scope.row.videoId)">删除</el-button>-->
          <!--        </template>-->

          <template slot-scope="scope">
            <el-popconfirm title="确定删除" @confirm="delButton(scope.row.videoId)">
              <el-button type="text" slot="reference">删除
              </el-button>
            </el-popconfirm>
          </template>

        </el-table-column>
      </el-table>
    </div>

    <div class="block">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="size"
          :total=total>
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "Videolist",
  data() {
    return {
      playAuth: 'aaaaaaa',
      playURL: "bbbbbbbb",
      description: '',
      fits: ['fill'],
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      searchForm: {
        title: ''
      },
      disabled: true,
      coverUrlList: [],
      tableData: [{
        coverURL: '视频封面',
        title: 'test',
        createTime: '2016-05-02',
        play: '播放',
        delete: '删除',
        videoId: '',

      }],
      multipleSelection: [],
      currentPage: 1,
      total: 20,
      size: 5
    }
  },
  created() {
    this.getVideoList()
  },
  methods: {
    playvideo(playURL) {
      let newWindow = this.$router.resolve({path: '/player/playvideo', query: {playaddress: playURL}})
      window.open(newWindow.href, '_blank');
    },
    getPlayAddress(id) {
      this.$axios.get('/eduvod/video/getPlayAddress' + `/${id}`)
          .then(res => {
            console.log(res)
            console.log("playURL:")
            console.log(res.data.data.playURL)
            this.playURL = res.data.data.playURL;
          })
          .catch(error => {
            alert("获取播放地址失败")
          })
    },
    getDescription(id) {
      this.$axios.get('/eduvod/video/getdescription' + `/${id}`)
          .then(res => {
            console.log(res)
            console.log("description:")
            console.log(res.data.data.description)
            this.playURL = res.data.data.description;
          })
          .catch(error => {
            alert("获取Description失败")
          })
    },
    getPlayAuth(id) {
      this.$axios.get('/eduvod/video/getPlayAuth' + `/${id}`)
          .then(res => {
            console.log("aaaaaaaaaaaaa")
            console.log(res)
            this.playauth = res.data.data.playAuth;
          })
          .catch(error => {
            alert("获取播放凭证失败")
          })
    },
    searchByTitle() {
      if (!this.searchForm.title) {
        this.getVideoList()
      } else {
        this.$axios.get('/eduvod/video/searchByTitle/' + `${this.searchForm.title}`)
            .then(res => {
              this.tableData = res.data.data.video;
              this.total = res.data.data.total;
              this.size = res.data.data.total;
            })
            .catch(err => {
              alert("查找失败")
            })
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.disabled = this.multipleSelection == 0
      //console.log(this.multipleSelection)
    },
    delButton(id) {
      const ids = []
      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.videoId)
        })
      }
      console.log(ids, "打印ids数据")
      this.$axios.get('/eduvod/video/deletevideo/' + `${ids}`)
          // this.$axios.get('http://localhost:8001/eduvod/video/deletevideo/',
          //     {params:{
          //       ids:ids,
          //         current:this.currentPage,
          //         size:this.size
          //       }})
          .then(res => {
            this.$message({
              showClose: true,
              message: '恭喜你，操作成功',
              type: 'success',
              onClose: () => {
                this.getVideoList()
              }
            })
            console.log(id)
          })
          .catch(err => {
            console.log(id)
            alert("删除失败")
          })
    },
    getVideoList() {
      this.$axios.get('/eduvod/video/getVideoList' + `/${this.currentPage}` + `/${this.size}`)
          // this.$axios.get('http://localhost:8001/eduvod/video/getVideoList',{params:{
          //   title:this.searchForm.name,
          //     current:this.currentPage,
          //     size:this.size
          //   }})
          .then(res => {
            console.log(res)
            this.tableData = res.data.data.video;
            this.total = res.data.data.total;

            // this.tableData.coverURL = res.data.data.video.coverURL
            // this.tableData.createTime = res.data.data.video.createTime
            // this.tableData.title = res.data.data.video.title

          })
          .catch(error => {
            alert("请求列表失败")
          })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getVideoList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getVideoList()
    }
  }
}
</script>

<style scoped>
.searchForm {
  float: left;
  margin-bottom: 2px;
  height: 42px;

}

.el-table {
  line-height: 24px;
}

.block {
  float: bottom;
}


</style>