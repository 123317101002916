<template>
  <div>
    <div style="display: flex;justify-content: space-between">
      <span>我管理的项目</span>
      <router-link to="/index">
        <el-button  type="primary" plain
                    style="color: #5556E5;padding: 7px 15px 7px 15px">创建项目</el-button>
      </router-link>
    </div>
    <el-card >
      <div slot="header" class="clearfix1" v-if="flag">
        <el-input placeholder="请输入项目名称" v-model="projectInput" style="width: 340px;">
          <el-button @click="getProjectList" slot="append" icon="el-icon-search" style="padding-left: 14px"></el-button>
        </el-input>
        <el-button type="text">
          <span class="el-dropdown-link"
                @click="flag=false"
                style=" color: #5556E5;
                        margin-left: 14px">
                高级搜索
                <i class="el-icon-arrow-down el-icon--right"
                   style="margin-left: 2px"></i>
          </span>
        </el-button>
      </div>
      <div slot="header" class="clearfix1" v-else>
        <el-input placeholder="请输入项目名称" v-model="projectInput" style="width: 340px">
          <el-button @click="getProjectList" slot="append" icon="el-icon-search" style="padding-left: 14px"></el-button>
        </el-input>
        <el-button type="text">
          <span class="el-dropdown-link"
                @click="flag=true"
                style=" color: #5556E5;
                        margin-left: 14px">
                高级搜索
                <i class="el-icon-arrow-up el-icon--right"
                   style="margin-left: 2px"></i>
          </span>
        </el-button>
        <el-form  :model="projectForm" ref="projectFormRef" :inline="true" style="background-color:#fafafa;margin-top: 4px">
          <el-form-item label="源语言" prop="origLang">
            <el-select v-model="projectForm.origLang" placeholder="请选择">
              <el-option v-for="(item,index) in this.origLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="目标语言" prop="targLang" style="margin-left: 0">
            <el-select v-model="projectForm.targLang" placeholder="请选择">
              <el-option v-for="(item,index) in this.targLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目状态" prop="status">
            <el-select v-model="projectForm.status" placeholder="请选择">
              <el-option v-for="(item,index) in this.statusOption" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标签" prop="label" style="margin-left: 0">
            <el-select v-model="projectForm.label" placeholder="请选择">
              <el-option v-for="(item,index) in this.labelOption" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="formItem4" style="margin-left: 900px">
            <el-button
                @click="getProjectList"
                type="primary" plain
                style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
            >
              搜索</el-button>
            <el-button
                @click="resetForm"
                type="primary" plain
                style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
            >
              重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <template>
          <el-popconfirm title="确定删除?" @confirm="toggleSelectionDelete()">
            <el-button
                :disabled="disabled"
                type="primary" plain
                slot="reference"
                style="color: #5556E5;padding: 7px 15px 7px 15px;border: 1px solid;margin-right: 2px">
              批量删除
            </el-button>
          </el-popconfirm>
          <!-- <el-button
              :disabled="disabled"
              type="primary" plain
              style="color: #5556E5;padding: 7px 15px 7px 15px;width: 88px;height: 30px;border: 1px solid"
              @click="toggleSelectionDistri()">
            批量分发</el-button> -->
        </template>
      </div>
      <el-table
          border
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange">
        <!--          复选框column-->
        <el-table-column
            type="selection">
        </el-table-column>
        <!--          项目名称-->
        <el-table-column
            prop="projectName"
            label="项目名称">
        </el-table-column>
        <el-table-column
            prop="createdUser"
            label="来源">
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态">
        </el-table-column>
        <!--                    <el-table-column-->
        <!--                        prop="progress"-->
        <!--                        label="进度"-->
        <!--                        width="100">-->
        <!--                    </el-table-column>-->
        <el-table-column
            prop="langDirection"
            label="语向">
        </el-table-column>
        <el-table-column
            prop="wordNum"
            width="80"
            label="原始字数">
        </el-table-column>
        <el-table-column
            prop="sentNum"
            width="80"
            label="句对数">
        </el-table-column>
        <el-table-column
            prop="createdTime"
            label="创建日期">
        </el-table-column>
        <el-table-column
            width="180"
            label="操作">
          <template slot-scope="scope" >
            <div v-if=judgeStatus(scope.row.status)>
              <!-- <el-button @click="handleView(scope.row)"
                         type="text" size="small"
                         style="color: #5556E5;">分配</el-button> -->
              <el-button @click="handleTrans(scope.row)"
                         type="text" size="small"
                         style="color: #5556E5;">开始翻译</el-button>
              <el-button @click="handleDelete(scope.row)"
                         type="text" size="small"
                         style="color: #5556E5;">删除</el-button>
              <el-tooltip class="item" effect="dark" content="请先翻译视频" :disabled="disabled1!==0" placement="top-start">
                <!-- <el-button @click="handlePlay(scope.row)"
                           type="text" size="small"
                           :disabled="disabled1===0"
                           style="color: #5556E5;">播放</el-button> -->
              </el-tooltip>
            </div>
            <div v-else>
              <router-link :to="{path:'/player/playvideo',query:{playaddress:vodVideoUrl}}" target="_blank">
                <el-button @click="handlePlay(scope.row)"
                           type="text" size="small"
                           style="color: #5556E5;">
                  播放
                </el-button>
              </router-link>
              <el-button @click="handleDownload(scope.row)"
                         type="text" size="small"
                         style="color: #5556E5;margin-left: 18px">
                下载
              </el-button>
              <el-button @click="handleDelete(scope.row)"
                         type="text" size="small"
                         style="color: #5556E5;margin-right: 13px">
                删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>


export default {
  name: "MyManaProject",
  data(){
    return{
      //源语言选项
      origLangOption:[
        {
          label:'中文',
          value:'中文'
        },
        {
          label:'英文',
          value:'英文'
        },
      ],
      //目标语言选项
      targLangOption:[
        {
          label:'中文',
          value:'中文'
        },
        {
          label:'英文',
          value:'英文'
        },
      ],
      //状态选项
      statusOption:[
        {
          label:'已完成',
          value:'已完成'
        },
        {
          label:'未翻译',
          value:'未翻译'
        },
        {
          label:'进行中',
          value:'进行中'
        },
      ],
      //标签选项
      labelOption:[
        {
          label:'机器学习',
          value:'机器学习'
        },
        {
          label:'语音识别',
          value:'语音识别'
        },
      ],
      projectForm:{
        origLang:'',
        targLang:'',
        status:'',
        label:''
      },
      //控制高级搜索的展开
      flag:true,
      //更新项目的相关参数
      taskId:'',
      vodVideoUrl:'',
      //控制播放按钮
      disabled1:0,
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //删除 分发按钮是否可用
      disabled: true,
      //input搜索框绑定的内容
      projectInput:'',
      //高级搜索下拉框绑定的内容
      //表格所绑定的内容
      tableData:[],
      //checkbox复选框按钮
      multipleSelection: []
    }
  },
  created() {
    this.getProjectList()
  },
  methods:{
    //下载视频，需要配置好nginx使用，或者关闭浏览器的跨域限制，此时应将将downloadFile的第一行第三个replace删除
  handleDownload(row){
      this.downloadMp4(row.vodVideoUrl,row.projectName)
    },
  downloadFile(url, filename) {
    //由于跨域问题存在，这里将vodUrl重定向到同源地址，服务器使用nginx进行反向代理到视频源
    const fileurl = url.replace(/\\/g, '/').replace("https","http").replace("http://ailemon-storage.oss-cn-shanghai.aliyuncs.com","http://101.34.231.172:81/download")
    var a = document.createElement('a')
    a.download = filename
    a.style.display = 'none'
  a.href = fileurl
  document.body.appendChild(a)
    document.body.removeChild(a)// 然后移除
     },
  downloadFileFromA(urlInput,filename) {
     //文件的下载
      var x = new XMLHttpRequest();
      x.open("GET", urlInput, true);
      x.responseType = 'blob';
      x.onload = function (e) {
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a');
        a.href = url
        a.download = filename ; //下载后的文件名
        a.click()
      }
      x.send();
      // this.$message.success('下载成功!')

  },
  // 下载服务器的MP4文件
downloadMp4(url,filename) {
    const downloadFileA = document.createElement('a')
  console.log(111111111111111)
    const fileurl = url.replace("https://aimooc.oss-cn-shanghai.aliyuncs.com","https://aimooc.cvnis.net/files")
    console.log(fileurl)
    document.body.append(downloadFileA)
    downloadFileA.href = fileurl
    downloadFileA.download = filename
    downloadFileA.click()
    document.body.removeChild(downloadFileA)

},
    //处理视频
    getTaskId(row) {
      this.$axios.post('/eduvod/video/getTaskId', {
        videoKey: row.videoKey,
        saveObjectPath: row.saveObjectPath
      })
          .then(response => {
            this.$message.success("正在翻译视频,请耐心等待翻译完成后进行查看。")
            this.taskId = response.data.data.taskId
            console.log("getTaskId方法中获取taskId成功", this.taskId)
            //更新taskId及状态
            this.updateProject(row)
                .then(()=>{
                  //输出正常
                  //console.log(row)
                  // 刷新列表页面
                  this.getProjectList().then(()=>{
                    console.log(row)
                    //请求处理好的地址
                    this.getVodVideoUrl(row)
                    console.log("getVodeVideoUrl参数1",row)
                  })
                })
          })
          .catch(error => {
            this.$message.error("翻译失败")
          })
    },
    getVodVideoUrl(row) {
      var _this = this
      const taskId = row.taskId === null ? this.taskId : row.taskId
        var s = 0;//设置轮询次数
        var _time;
        _time = setInterval(() => {
        //此处写自己的业务逻辑。。。。。。
        this.$axios.post('/eduvod/video/getVodVideoUrl',
          {taskId: taskId})
          .then(function (response) {
            if(response.data.data.resultStatus === 0){
              console.log(response.data.data.resultStatus);
            }else if(response.data.data.resultStatus === 1){
            console.log(response.data.data.resultStatus)
            _this.vodVideoUrl = response.data.data.vodVideoUrl
            console.log("执行getVodVideoUrl方法成功")
            _this.$notify({
              title: '友情提示',
              message: '视频翻译完成',
              type: 'success'
            });
            //更新数据
            _this.updateProject(row).then(()=>{
              //刷新页面
              _this.getProjectList()
            })
            clearInterval(_time); //清理定时任务
           
            }
            else {
                _this.$message.error("视频翻译失败,请练习系统管理员。")
                  clearInterval(_time); //清理定时任务
            }

          })
          .catch(function (error) {
            console.log(error);
          });
    }, 3000);//3000:每3秒轮询一次

    },
    //播放功能
    handlePlay(row){
      this.vodVideoUrl = row.vodVideoUrl
      console.log(this.vodVideoUrl)
    },
    //判断状态
    judgeStatus(status){
      //console.log("aaaaaaaaaaaaaaaa",status)
      if(status!=="已完成")
        return true
    },
    //删除功能
    handleDelete(row){
      this.$axios.get("/project/removeproject/"+row.pid)
      .then(res =>{
        })
      this.getProjectList()
    },
    //翻译功能
    async handleTrans(row){
      //如果状态为进行中,可以直接请求vodVideoUrl
      if(row.status==="进行中")
        await this.getVodVideoUrl(row)
      else
        await  this.getTaskId(row)
    },
    async updateProject(row){
      // console.log(row)
      // console.log(row.pid)
      //翻译完成之后 可以播放
      const {data:result} = await this.$axios.post("/project/updateproject",
          {
            pid:row.pid,
            taskId:this.taskId,
            vodVideoUrl:this.vodVideoUrl
          })
      if(result.code!==200)
        return this.$message.error('视频信息更新失败')
      this.disabled1++;
    },
    //批量删除项目
    async removeProject(ids){
      const {data:result} = await  this.$axios.get("/project/removeproject/"+`${ids}`)
      if(result.code!==200)
        return this.$message.error('批量删除失败')
      this.getProjectList()
    },
    //重置表单
    resetForm(){
      this.$refs.projectFormRef.resetFields();
      this.getProjectList();
    },
    //获取项目列表
    async getProjectList(){
      const {data:result} = await this.$axios.post("/project/projectlist",{
        uid:this.$store.getters.GET_UID,
        currentPage:this.currentPage,
        pageSize:this.size,
        projectName:this.projectInput,
        origLang:this.projectForm.origLang,
        targLang:this.projectForm.targLang,
        status:this.projectForm.status,
        label:this.projectForm.label,
        username:this.$store.getters.GET_USERNAME
      })
      console.log('result:',result)
      if(result.code!==200)
        return this.$message.error('获取项目列表失败')
      this.tableData = result.data.data.records;
      this.total = result.data.data.total

    },
    //批量删除功能
    toggleSelectionDelete(row) {
      //有参数代表的是针对的具体的行 可认为是删除功能
      const rows = []
      console.log(rows)
        let ids = []
        //循环遍历多选框
        //拿出其中每项的pid发给后端
        console.log(this.multipleSelection)
        for(let i=0;i<this.multipleSelection.length;i++){
					ids.push(this.multipleSelection[i].pid)//把取出的对象push到数组里
				}
        this.removeProject(ids)
        this.$refs.multipleTable.clearSelection();
      
    },
    //批量分发功能
    toggleSelectionDistri(row) {
      const rows = []
      if (row) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      if(val) {
                    val.forEach(one => {
                        one.yes = true
                    })
                }
      this.multipleSelection = [...val]
      this.disabled = this.multipleSelection == 0
    },
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getProjectList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getProjectList()
    },
  }
}
</script>

<style>
.el-card>.el-card__header{
  padding:20px 18px 6px 18px;
}
.el-card>.el-card__body{
  padding-top:6px;
}
.el-button + .el-button{
  padding: 2px 5px 2px 5px;
}
</style>