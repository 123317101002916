<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>视频翻译</el-breadcrumb-item>
<!--      <el-breadcrumb-item>活动列表</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>活动详情</el-breadcrumb-item>-->
    </el-breadcrumb>
    <el-card class="box" style="margin-top: 10px;">
      <div slot="header" class="clearfix1" style="padding: 0px">
        <span>创建项目</span>
      </div>
<!--      第一步-->
      <div v-if="flag===0">
<!--        上传框-->
        <el-upload
            class="uploadVideo"
            ref="uploadVideoRef"
            :on-success="handleVodUploadSuccess"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            :auto-upload="true"
            accept=".mp4"
            :data="ObjectData"
            drag
            :action="ObjectData.host"
            :before-upload="beforeUpload"
            multiple>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>来创建视频翻译项目。
            <br>仅支持MP4格式。
            <br>最多上传一个文件，且不超过500M</div>
        </el-upload>

        <el-button type="primary" plain
                   :disabled="disabled1"
                   @click="enterNext"
                   style="margin-left: 805px;margin-top: 10px;padding: 7px 20px 7px 20px">
          下一步</el-button>
      </div>
<!--      第二步-->
      <div v-else-if="flag === 1">
        <el-form :model="projectForm"
                 :rules="rules"
                 ref="projectFormRef"
                 label-width="100px"
                 class="projectFormCla">
          <div style="display:flex">
          <div>
            <el-form-item
                class="item1"
                label="项目名称"
                prop="name"
                style="margin-left: 10px;">
              <el-input v-model="projectForm.name" class="nameInput"></el-input>
            </el-form-item>
            <el-form-item label="源语言" prop="origLang" style="margin-left: 10px;">
              <el-select v-model="projectForm.origLang" style="width: 242px" placeholder="请选择" >
                <el-option v-for="(item,index) in this.origLangOptions" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="目标语言" prop="targLang" style="margin-left: 10px;">
              <el-select v-model="projectForm.targLang" style="width: 124px;width: 242px" placeholder="请选择" >
                <el-option v-for="(item,index) in this.targLangOptions" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="领域" style="margin-left: 10px" prop="field" >
              <el-select v-model="projectForm.projField" placeholder="请选择" style="width: 242px">
                <el-option v-for="(item,index) in this.projFieldOptions" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标签" style="margin-left: 10px" prop="field" >
              <el-select v-model="projectForm.projLabel" placeholder="请选择" style="width: 242px">
                <el-option v-for="(item,index) in this.projLabelOptions" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item
                class="myrequire"
                label="要求"
                prop="projRequire"
                style="margin-left: 50px;">
              <el-input
                        class="requirement"
                        type="textarea"
                        :rows="10"
                        v-model="projectForm.projRequire"
                        placeholder="请输入">
              </el-input>
            </el-form-item>
            <el-upload
                class="uploadFile"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-change="handleChangeUploadFile"
                :file-list="uploadFileList">
              <el-button size="small" type="primary" plain
                         style="margin-left: 148px;margin-top: 10px;padding: 7px 20px 7px 20px">上传参考文件</el-button>
            </el-upload>
          </div>
            <div>
              <el-button size="small" type="primary"
                         plain
                         @click="enterLast"
                         style="margin-left: 128px;margin-top: 210px;padding: 7px 20px 7px 20px">上一步
              </el-button>
              <el-button size="small" type="primary" plain
                         @click="enterNext"
                         style="margin-left: 48px;margin-top: 20px;padding: 7px 20px 7px 20px">下一步
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
<!--      第三步-->
      <div v-else-if="flag === 2">
        <div style="display: flex;">
          <span style="margin-right: 40px;padding-top: 2px">项目设置:</span>
          <template>
            <el-select v-model="value" placeholder="系统默认设置" size="mini">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </template>
        </div>
<!--        自定义设置界面-->
        <div v-if="value==='2'" style="display: flex">
          <el-card class="box3"  style="margin-top: 10px;width:800px;">
            <div slot="header" class="clearfix2" style="padding: 0px">
              <el-menu :default-active="activeIndex" class="settingMenu" mode="horizontal" @select="handleSelect">
                <el-menu-item index="1">文件解析</el-menu-item>
                <el-menu-item index="2">语料库配置</el-menu-item>
                <el-menu-item index="3">术语库</el-menu-item>
                <el-menu-item index="4">机器引擎配置</el-menu-item>
                <el-menu-item index="5">语音识别配置</el-menu-item>
                <el-menu-item index="6">任务分配模板</el-menu-item>
               </el-menu>
            </div>
            <div>
  <!--            index===1时-->
              <div v-if="activeIndex==='1'">
                <span>解析为纯文本:</span>
                <el-switch
                    style="margin-left: 8px"
                    v-model="activeSwitch">
                </el-switch>

              </div>
              <!--            index===2时-->
              <div v-if="activeIndex==='2'">
                <h2>语料库配置界面</h2>
              </div>
              <!--            index===3时-->
              <div v-if="activeIndex==='3'">
                <h2>术语库界面</h2>
              </div>
              <!--            index===4时-->
              <div v-if="activeIndex==='4'">
                <h2>机器引擎配置界面</h2>
              </div>
              <!--            index===5时-->
              <div v-if="activeIndex==='5'">
                <h2>语音识别配置界面</h2>
              </div>
              <!--            index===6时-->
              <div v-if="activeIndex==='6'">
                <h2>任务分配模板界面</h2>
              </div>
            </div>
          </el-card>
<!--          //公共按钮界面-->
          <div style="width: 281px">
            <el-button size="small" type="primary"
                       plain
                       @click="enterLast"
                       style="margin-left: 108px;margin-top: 200px;padding: 7px 32px 7px 20px">上一步
            </el-button>
            <el-button size="small" type="primary"
                       plain
                       @click="enterLast"
                       style="margin-left: 108px;margin-top: 20px;padding: 7px 20px 7px 20px">立即创建
            </el-button>
          </div>
        </div>
<!--        默认设置界面-->
        <div v-else>
          <el-button size="small" type="primary"
                     plain
                     @click="enterLast"
                     style="margin-left: 75px;margin-top: 58px;padding: 7px 32px 7px 20px">上一步
          </el-button>
          <el-button size="small" type="primary"
                     plain
                     @click="newProject"
                     style="margin-left: 15px;margin-top: 10px;padding: 7px 20px 7px 20px">创建项目
          </el-button>
        </div>

      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Transvideo",
  data(){
    return{
      //控制下一步的进行
      disabled1:true,
      actionUrl:"https://aimooc-testenv.oss-cn-shanghai.aliyuncs.com",
      //视频上传相关参数
      video: {
        videoSourceId: '',
        videoPlayAuth: '',
        videoPlayAddress: '',
        videoKey: "test1/test2.mp4",
        videoTitle: '',
        saveObjectPath: "test1",
        taskId: "34c4ecad-b28d-4cd1-a228-d7b8cc19a120",
        vodVideoUrl: "https://video.ailemon.net/sv/4397915f-17b7e616a41/4397915f-17b7e616a41.mp4"
      },
      //oss直传后端返回签名相关参数
      ObjectData: {
          OSSAccessKeyId:'',
          policy:'',
          key:'',
          Signature:'',
          host:"https://aimooc-testenv.oss-cn-shanghai.aliyuncs.com",
          dir:'',
          success_action_status:'200',
      },
      headerObj:{
          'satoken':sessionStorage.getItem("token")
      },
      //滑块控制值
      activeSwitch:false,
      //自定义设置默认活跃的index
      activeIndex:'1',
      //上传参考文件列表
      uploadFileList:[],
      //控制第一步页面的显示与关闭
      flag:0,
      //上传数据列表
      fileList:[],
      //项目设置的可选内容
      options:[
        {
          value:'1',
          label:'系统默认设置'
        },
        {
          value:'2',
          label:'自定义设置'
        },
      ],
      //项目设置的默认选中内容
      value:'1',
      //源语言
      origLangOptions:[
        {
          value:"英语",
          label:"英语"
        },
        {
          value: "中文",
          label:"中文"
        }
      ],
      //目标语言
      targLangOptions:[
        {
          value:"英语",
          label:"英语"
        },
        {
          value: "中文",
          label:"中文"
        },
      ],
      //领域
      projFieldOptions:[
        {
          value:'计算机',
          label:'计算机'
        },
        {
          value:'人工智能',
          label:'人工智能'
        },
      ],
      //标签
      projLabelOptions:[
        {
          value:'语音识别',
          label:'语音识别'
        },
        {
          value:'机器学习',
          label:'机器学习'
        },
      ],
      //第二步的表单
      projectForm:{
        //项目名称
        name:'',
        //创建时间
        createdTime:'',
        //源语言
        origLang:'中文',
        //目标语言
        targLang:'英语',
        //领域
        projField:'',
        //标签
        projLabel:'',
        //要求
        projRequire:'',

      },
      //校验规则
      rules: {
        name:[
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ]
      },
    }
  },
  methods:{
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "mp4";
      if (!extension ) {
        this.$message({
          message: "上传文件只能是.mp4格式!",
          type: "warning",
        });
      }
      //在上传前进行服务器签名
      let _self = this;
      return new Promise((resolve,reject)=>{
        //请求后端
        this.$axios.get("/eduoss/video/getToken")
        .then(res =>{
          _self.ObjectData.OSSAccessKeyId=res.data.data.accessid,
          _self.ObjectData.policy=res.data.data.policy,
          _self.ObjectData.Signature=res.data.data.signature,
          _self.ObjectData.host=res.data.data.host,
          _self.ObjectData.dir=res.data.data.dir,//直传oss的服务器地址
          _self.ObjectData.key=_self.ObjectData.dir+"/"+file.name,
          console.log(_self.ObjectData.dir),
          resolve(true)     //继续上传
        })
        .catch(error => {
            console(error),
            reject(false)    //停止上传
          })
    })
    },
    //创建项目
    async newProject(){
      const {data:result} = await this.$axios.post("/project/newproject",
          {
            field: this.projectForm.projField,
            label: this.projectForm.projLabel,
            origLang: this.projectForm.origLang,
            targLang: this.projectForm.targLang,
            projectName: this.projectForm.name,
            requirement: this.projectForm.projRequire,
            saveObjectPath: this.video.saveObjectPath,
            videoKey: this.video.videoKey
          })
      console.log('result:',result)
      if(result.code===4000)
        return this.$message.error('项目名已存在,请重新输入。')
      else if(result.code!==200)
        return this.$message.error('项目注册出现位置异常,注册失败。')
      //创建项目成功之后台跳转回首页
      this.$message.success("创建项目成功,即将回到首页。")
      setTimeout(()=>{
        this.$router.push("/index")
      },1500)
    },
    guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
},
    //视频上传成功的动作
    handleVodUploadSuccess(file) {      
      //this.video.videoSourceId = response.data.videoId
      this.video.videoKey = this.ObjectData.key
      this.video.saveObjectPath = this.ObjectData.dir
      //this.video.videoTitle = response.data.videoTitle
      console.log("获取videokey成功")
      this.disabled1 = false
      //上传视频到vod的相关功能
      // const _this = this
      // let fl = new FormData()
      // fl.append('file', this.files)
      // this.$axios.post('/eduvod/video/uploadVideoVod', fl)
      //     .then(response => {
      //       this.video.videoSourceId = response.data.data.videoSourceId
      //       console.log(this.video.videoSourceId)
      //       let loading = this.$loading({
      //         lock: true,
      //         text: "正在上传中，请耐心等待。",
      //         background: 'rgba(0,0,0,0.7)'
      //       })
      //       loading.close()
      //       this.$message({
      //         showClose: true,
      //         message: '恭喜你，上传成功',
      //         type: 'success',
      //       })
      //       console.log("上传成功")
      //       this.disabled0 = true
      //       this.disabled1 = false
      //       this.active = !this.disabled1
      //
      //     })
      //     .catch(error => {
      //       console.log("上传失败")
      //       this.$message.error('上传失败')
      //     })
    },
    //执行action
    // submitUpload() {
    //   this.$refs.upload.submit();
    // },
    //将文件从列表中移除
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    //预览文件
    handlePreview(file) {
      console.log(file);
    },
    //进入下一步
    enterNext(){
      this.flag++
    },
    //进入上一步
    enterLast(){
      this.flag--
    },
    //上传参考文件钩子
    handleChangeUploadFile(){

    },
    //自定义设置界面的导航点击函数
    handleSelect(index){
      this.activeIndex = index
      // console.log(this.activeIndex)
    }
  }
}
</script>

<style>
.box>.el-card__header{
  padding: 10px 20px 10px 20px;
}
.box>.el-card__body{
  height: 350px;
  padding-top: 10px;
}

.el-upload>.el-upload-dragger{
  width: 994px;
}
.el-upload-list{
  width: 210px;
  height: 40px;
  display: flex;
  align-items: center;
}
.el-form-item__content{
  width: 242px;
}
.requirement >.el-input__inner{
  height: 160px;

}
/*自定义高级配置页面*/
.box3>.el-card__header{
  padding: 0;
  border-bottom: none;
}
.box3>.el-card__body{
  height:210px;
}
.item1>.el-form-item__content{
  width: 242px;
}
.myrequire>.el-form-item__content{
  width: 242px;
}
.nameInput>.el-input__inner{
  width: 242px;
}

</style>