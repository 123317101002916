<template>
  <div>
    <div>
      <el-menu  style="height: 50px" :default-active="activeIndex" class="settingMenu" mode="horizontal" @select="handleSelect">
        <el-menu-item style="height: 50px" index="1">译员质量</el-menu-item>
        <el-menu-item style="height: 50px" index="2">项目质量</el-menu-item>
      </el-menu>
    </div>
    <el-card v-if="activeIndex===`1`">
      <div style="margin-bottom: 25px">
        <span>
          ------------------------------------------------
        </span>
        <br>议员质量评价空间。格式待定<br>
        <span>
          ------------------------------------------------
        </span>
      </div>
      <el-form  :model="memberForm" :inline="true" style="background-color:#fafafa;">
        <el-form-item label="项目ID">
          <el-input v-model="memberForm.name" style="width: 124px" placeholder="项目ID"></el-input>
        </el-form-item>
        <el-form-item label="译员" style="margin-left: 0">
          <el-select v-model="memberForm.transMember" placeholder="请选择">
            <el-option v-for="(item,index) in this.memberOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-col :span="11">
            <el-date-picker class="date1" type="date" placeholder="起始日期" v-model="memberForm.date1" style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-col :span="11">
            <el-date-picker class="date2" type="date" placeholder="结束日期" v-model="memberForm.date2" style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>
      <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;margin-top: 10px;">
        <!--          项目名称-->
        <el-table-column
            prop="date"
            label="时间"
        >
        </el-table-column>
        <el-table-column
            prop="transMember"
            label="译员">
        </el-table-column>
        <el-table-column
            prop="score"
            label="分数"
        >
        </el-table-column>
        <el-table-column
            prop="id"
            label="项目ID"
        >
        </el-table-column>
        <el-table-column
            prop="checkedMember"
            label="审校员"
        >
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button @click="handleView(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">查看</el-button>
            <el-button @click="handleEdit(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">编辑</el-button>

          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
    <el-card v-else>
      <div style="margin-bottom: 25px">
        <span>
          ------------------------------------------------
        </span>
        <br>项目质量评价空间。格式待定<br>
        <span>
          ------------------------------------------------
        </span>
      </div>
      <el-form  :model="memberForm2" :inline="true" style="background-color:#fafafa;">
        <el-form-item label="项目ID">
          <el-input v-model="memberForm2.name" style="width: 124px" placeholder="项目ID"></el-input>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-col :span="11">
            <el-date-picker class="date1" type="date" placeholder="起始日期" v-model="memberForm2.date1" style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-col :span="11">
            <el-date-picker class="date2" type="date" placeholder="结束日期" v-model="memberForm2.date2" style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>
      <el-table
          border
          :data="tableData2"
          tooltip-effect="dark"
          style="width: 100%;margin-top: 10px;">
        <!--          项目名称-->
        <el-table-column
            prop="date"
            label="时间"
        >
        </el-table-column>
        <el-table-column
            prop="transMember"
            label="译员">
        </el-table-column>
        <el-table-column
            prop="score"
            label="分数"
        >
        </el-table-column>
        <el-table-column
            prop="id"
            label="项目ID"
        >
        </el-table-column>
        <el-table-column
            prop="checkedMember"
            label="审校员"
        >
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button @click="handleView(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">查看</el-button>
            <el-button @click="handleEdit(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">编辑</el-button>

          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "ProjectCheckTable",
  data(){
    return{
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //译员表格数据
      tableData: [{
        date:'2021-11-16',
        transMember: '译员1',
        score:'97',
        id:'xidian1234',
        checkedMember:'admin',
      },
      ],
      //项目质量界面的表格
      tableData2: [{
        date:'2021-11-21',
        transMember: '译员2',
        score:'79',
        id:'xidian4321',
        checkedMember:'test',
      },
      ],
      memberOption:[
        {
          label:'译员1',
          value:'译员1'
        },
        {
          label:'译员2',
          value:'译员2'
        },
      ],
      //自定义设置默认活跃的index
      activeIndex:'1',
      //译员质量的表
      memberForm:{
        id:"",
        transMember:'',
        date1:'',
        date2:'',
      },
      //项目质量界面的表
      memberForm2:{
        id:"",
        date1:'',
        date2:'',
      },
    }
  },
  methods:{
    handleView(row){
      console.log(row,"执行了handleView")
    },
    handleEdit(row){
      console.log(row,"执行了handleEdit")
    },
    handleSelect(index){
      this.activeIndex = index
      // console.log(this.activeIndex)
    },
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getVideoList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getVideoList()
    },
  }
}
</script>

<style>

</style>