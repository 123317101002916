<template>
  <div>
    <el-card>
      <!--      最头部-->
      <div slot="header" style="display: flex;justify-content: space-between">
        <span>
          语料查询
        </span>
        <div>
          <span style="font-size: 12px">
            显示上下文
          </span>
          <el-switch
              v-model="switchValue"
              active-color="#5556E5"
              inactive-color="#EBECF0">
          </el-switch>
        </div>
      </div>
      <!--      搜索区域-->
      <el-card v-if="flag">
        <div slot="header">
          <span>全部术语</span>
        </div>

        <el-input placeholder="请输入术语名称" v-model="langLibSearchInput" style="width: 340px">
          <el-button slot="append" icon="el-icon-search" style="padding-left: 14px"></el-button>
        </el-input>
        <el-button type="text" @click="flag=false">
          <span class="el-dropdown-link"
                style=" color: #5556E5;
                        margin-left: 14px">
                高级搜索
                <i class="el-icon-arrow-down el-icon--right"
                   style="margin-left: 2px"></i>
          </span>
        </el-button>
      </el-card>
      <el-card v-else>
        <div slot="header">
          <span>全部术语</span>
        </div>

        <el-input placeholder="请输入术语名称" v-model="langLibSearchInput" style="width: 340px;margin-bottom: 10px">
          <el-button slot="append" icon="el-icon-search" style="padding-left: 14px"></el-button>
        </el-input>
        <el-button type="text" @click="flag=true">
          <span class="el-dropdown-link"
                style=" color: #5556E5;
                        margin-left: 14px">
                高级搜索
                <i class="el-icon-arrow-up el-icon--right"
                   style="margin-left: 2px"></i>
          </span>
        </el-button>
          <el-form  :model="langForm" :inline="true">
            <el-form-item label="术语库名称" class="item1">
              <el-input v-model="langForm.name" style="width: 124px" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="语种" style="margin-left: 0">
              <el-select v-model="langForm.lang" placeholder="请选择">
                <el-option v-for="(item,index) in this.langOption" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="更新时间" class="formItem3">
              <el-col :span="11">
                <el-date-picker class="date1" type="date" placeholder="起始日期" v-model="langForm.date1" style="width: 100%;"></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">&nbsp;-</el-col>
              <el-col :span="11">
                <el-date-picker class="date2" type="date" placeholder="结束日期" v-model="langForm.date2" style="width: 100%;"></el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item class="formItem4">
              <el-button
                  type="primary" plain
                  style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
              >
                搜索</el-button>
              <el-button
                  type="primary" plain
                  style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
              >
                重置</el-button>
            </el-form-item>
          </el-form>
      </el-card>
      <!--      搜索区域结束 表格区域开始-->
      <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;margin-top: 10px;">
        <!--          项目名称-->
        <el-table-column
            prop="english"
            label="英文">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: space-between">
              <span>{{scope.row.english}}</span>
              <el-button type="text" icon="el-icon-view" style="color:#5556E5">

              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="chinese"
            label="中文">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: space-between">
              <span>{{scope.row.chinese}}</span>
              <el-button type="text" icon="el-icon-view" style="color:#5556E5">

              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="operator"
            label="操作">
          <template slot-scope="scope">
            <el-button @click="handleView(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">查看修订记录</el-button>

          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "MajorLangLibSearch",
  data(){
    return{
      //langForm
      langForm:{
        name:"",
        lang:'',
        date1:'',
        date2:'',
      },
      //控制高级按钮的显示
      flag:true,
      //搜索框绑定的内容
      langLibSearchInput:'',
      //滑块控制值
      switchValue:true,
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //表格数据
      tableData: [{
        english: 'Hello,world',
        chinese:'你好,世界',
      },
      ]
    }
  },
  methods:{
    handleView(row){
      console.log(row,"执行了handleView")
    },
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getVideoList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getVideoList()
    },
  }
}
</script>

<style>
.el-date-editor>.el-input__inner{
  padding-right: 0px;
  width: 130px;
}
.el-form>.el-form-item{
  margin-bottom: 0px;
}
.el-form-item__content{
  width: 150px;
}
.formItem3>.el-form-item__content{
  width: 300px;
}
.formItem4{
  margin-left: 50px;
}
.formItem4>.el-form-item__content{
  width: 100px;
}
.item1>.el-form-item__content{
  width: 130px;
}
</style>