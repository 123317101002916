<template>
  <div>
    <el-card>
<!--      最头部-->
      <div slot="header" style="display: flex;justify-content: space-between">
        <span>
          语料查询
        </span>
        <div>
          <span style="font-size: 12px">
            显示上下文
          </span>
          <el-switch
              v-model="switchValue"
              active-color="#5556E5"
              inactive-color="#EBECF0">
          </el-switch>
        </div>
      </div>
<!--      搜索区域-->
      <el-card v-if="flag">
        <div slot="header">
          <span>全部语料</span>
        </div>

        <el-input placeholder="请输入要搜索的内容" v-model="langLibSearchInput" style="width: 340px">
          <el-button slot="append" icon="el-icon-search" style="padding-left: 14px"></el-button>
        </el-input>
        <el-button type="text">
          <span class="el-dropdown-link"
                @click="flag=false"
                style=" color: #5556E5;
                        margin-left: 14px">
                高级搜索
                <i class="el-icon-arrow-down el-icon--right"
                   style="margin-left: 2px"></i>
          </span>
        </el-button>
      </el-card>
      <el-card v-else>
        <div slot="header">
          <span>全部语料</span>
        </div>
        <el-input placeholder="请输入要搜索的内容" v-model="langLibSearchInput" style="width: 340px;margin-bottom: 7px">
          <el-button slot="append" icon="el-icon-search" style="padding-left: 14px"></el-button>
        </el-input>
        <el-button type="text">
          <span class="el-dropdown-link"
                @click="flag=true"
                style=" color: #5556E5;
                        margin-left: 14px">
                高级搜索
                <i class="el-icon-arrow-up el-icon--right"
                   style="margin-left: 2px"></i>
          </span>
        </el-button>
        <el-form  :model="langLibForm" :inline="true">
          <el-form-item label="项目名称" class="nameItem">
            <el-input v-model="langLibForm.name" style="width: 150px;margin-bottom: 10px" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="匹配方式" style="margin-left: 0">
            <el-select v-model="langLibForm.type" placeholder="请选择">
              <el-option v-for="(item,index) in this.typeOption" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="源语言" style="margin-left: 0"  label-position="left" label-width="100px" prop="lang">
            <el-select v-model="langLibForm.origLang" placeholder="请选择">
              <el-option v-for="(item,index) in this.origLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="目标语言" style="margin-left: 0"  label-position="left" label-width="100px" prop="lang">
            <el-select v-model="langLibForm.targLang" placeholder="请选择">
              <el-option v-for="(item,index) in this.targLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="更新时间" class="formItem3">
            <el-col :span="11">
              <el-date-picker class="date1" type="date" placeholder="起始日期" v-model="langLibForm.date1" style="width: 100%;"></el-date-picker>
            </el-col>
            <el-col class="line" :span="2">&nbsp;-</el-col>
            <el-col :span="11">
              <el-date-picker class="date2" type="date" placeholder="结束日期" v-model="langLibForm.date2" style="width: 100%;"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item class="formItem4">
            <el-button
                type="primary" plain
                style="color: #5556E5;margin-left:10px ;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
            >
              搜索</el-button>
            <el-button
                type="primary" plain
                style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
            >
              重置</el-button>
          </el-form-item>
        </el-form>
      </el-card>
<!--      搜索区域结束 表格区域开始-->
      <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;margin-top: 10px;">
        <!--          项目名称-->
        <el-table-column
            prop="language"
            label="语向"
        >
        </el-table-column>
        <el-table-column
            prop="origSentence"
            label="原文"
        >
        </el-table-column>
        <el-table-column
            prop="tranSentence"
            label="译文"
            width="121px">
        </el-table-column>
        <el-table-column
            prop="modifiedUser"
            label="创建人"
        >
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="更新时间"
        >
        </el-table-column>
        <el-table-column
            prop="operator"
            label="操作">
          <template slot-scope="scope">
            <el-button @click="handleView(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">详情</el-button>
            <el-button @click="handleEdit(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">编辑</el-button>
            <el-button @click="handleDelete(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "LangLibSearch",
  data(){
    return{
      //高级搜索表单
      langLibForm:{
        name:'',
        //匹配方式
        type:'',
        origLang:'',
        targLang:'',
        date1:'',
        date2:''
      },
      //控制高级搜索的展开
      flag:true,
      //匹配方式选项
      typeOption:[
        {
          label:'模糊匹配',
          value:',模糊匹配'
        },
        {
          label:'精准匹配',
          value:',精准匹配'
        },
      ],
      //源语言选项
      origLangOption:[
        {
          label:'中文',
          value:'中文'
        },
        {
          label:'英文',
          value:'英文'
        },
      ],
      //目标语言选项
      targLangOption:[
        {
          label:'中文',
          value:'中文'
        },
        {
          label:'英文',
          value:'英文'
        },
      ],
      //搜索框绑定的内容
      langLibSearchInput:'',
      //滑块控制值
      switchValue:true,
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //表格数据
      tableData: [{
        language: '中文-英文',
        origSentence:'你好,世界',
        tranSentence:'Hello,world',
        modifiedUser:'admin',
        updateTime:'2021-11-16',
      },
      ]
    }
  },
  methods:{
    handleView(row){
      console.log(row,"执行了handleView")
    },
    handleEdit(row){
      console.log(row,"执行了handleEdit")
    },
    handleDelete(row){
      console.log(row,"执行了handleDelete")
    },
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getVideoList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getVideoList()
    },
  }
}
</script>

<style>
.el-col>.el-date-editor>.el-input__inner{
  padding-right: 0px;
  width: 150px;
}
.el-form>.el-form-item{
  margin-bottom: 0px;
}
.el-form-item__content{
  width: 150px;
}
.formItem3>.el-form-item__content{
  width: 320px;
}
.formItem4{
  margin-left: 50px;
}
.formItem4>.el-form-item__content{
  width: 100px;
}
</style>