<template>
  <div>
    <el-card>
      <div slot="header" style="display: flex;justify-content: space-between">
        <span>
          语料库管理
        </span>
        <div>
          <router-link to="/language/newllib">
            <el-button  type="primary" plain
                        style="color: #5556E5;padding: 7px 15px 7px 15px;border: none;margin-right: 5px">新建语料库</el-button>
          </router-link>

          <el-button  type="primary" plain
                      @click="dialogVisible = true"
                      style="color: #000000;padding: 7px 15px 7px 15px">合并语料库</el-button>
        </div>
      </div>
      <el-form  :model="langForm" :inline="true" style="background-color:#fafafa;">
        <el-form-item label="语料库名称">
          <el-input v-model="langForm.name" style="width: 124px" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="领域" style="margin-left: 0">
          <el-select v-model="langForm.field" placeholder="请选择">
            <el-option v-for="(item,index) in this.fieldOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="更新时间" class="formItem3">
          <el-col :span="11">
            <el-date-picker class="date1" type="date" placeholder="起始日期" v-model="langForm.date1" style="width: 100%;"></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">&nbsp;-</el-col>
          <el-col :span="11">
            <el-date-picker class="date2" type="date" placeholder="结束日期" v-model="langForm.date2" style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item class="formItem4">
          <el-button
              type="primary" plain
              style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
              >
            搜索</el-button>
          <el-button
              type="primary" plain
              style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
          >
            重置</el-button>
        </el-form-item>
      </el-form>
      <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;margin-top: 10px;">
        <!--          项目名称-->
        <el-table-column
            prop="id"
            label="ID"
            >
        </el-table-column>
        <el-table-column
            prop="name"
            label="语料库名称"
            >
        </el-table-column>
        <el-table-column
            prop="sentNum"
            label="句对数"
            width="121px">
        </el-table-column>
        <el-table-column
            prop="field"
            label="领域"
            >
        </el-table-column>
        <el-table-column
            prop="modifiedUser"
            label="创建人"
            >
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="更新时间"
            >
        </el-table-column>
        <el-table-column
            prop="operator"
            label="操作">
          <template slot-scope="scope">
            <el-button @click="handleView(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">查看</el-button>
            <el-button @click="handleEdit(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">编辑</el-button>
            <el-dropdown trigger="click">
      <span class="el-dropdown-link" style="color: #5556E5;">
        更多<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item >删除</el-dropdown-item>
                <el-dropdown-item >原文去重</el-dropdown-item>
                <el-dropdown-item >同步</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
    <el-dialog title="合并语料库" :visible.sync="dialogVisible" width="500px">
      <el-form :model="langForm"  class="majorFormClass">
        <el-form-item label="接收语料库" style="margin-left: 0;margin-bottom: 12px" required label-width="150px" label-position="left">
          <el-select v-model="langForm.lib1" placeholder="请选择" class="lib1Class">
            <el-option v-for="(item,index) in this.lib1Option" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="被合并语料库" style="margin-left: 0;margin-bottom: 6px" required label-width="150px" label-position="left">
          <el-select v-model="langForm.lib2" placeholder="请选择" class="lib1Class">
            <el-option v-for="(item,index) in this.lib2Option" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="重复原文导入设置" prop="type"  class="item" label-width="150px" label-position="left">
          <el-radio-group v-model="langForm.setting">
            <el-radio label="合并句对"></el-radio>
            <el-radio label="覆盖"></el-radio>
            <el-radio label="不导入"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
            type="primary" plain
            style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
            @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" plain
                   style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
                   @click="dialogTableVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "LangLib",
  data(){
    return{
      dialogVisible: false,
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //fieldOption 领域的选项
      fieldOption:[
        {
          label:'人工智能',
          value:'人工智能'
        },
        {
          label:'计算机',
          value:'计算机'
        },
      ],
      lib1Option:[
        {
          label:'人工智能',
          value:'人工智能'
        },
        {
          label:'计算机',
          value:'计算机'
        },
      ],
      lib2Option:[
        {
          label:'人工智能',
          value:'人工智能'
        },
        {
          label:'计算机',
          value:'计算机'
        },
      ],
      //LangForm
      langForm:{
        lib1:'',
        lib2:'',
        setting:'合并句对'
      },
      //表格数据
      tableData: [{
        id: '1',
        name:'test',
        sentNum:'21',
        field:'人工智能,计算机',
        modifiedUser:'admin',
        updateTime:'2021-11-16',
      },
      ]
    }
  },
  methods:{
    handleView(row){
      console.log(row,"执行了handleView")
    },
    handleEdit(row){
      console.log(row,"执行了handleEdit")
    },
    handleMore(row){
      console.log(row,"执行了handleMore")
    },
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getVideoList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getVideoList()
    },
  }
}
</script>

<style scoped>
.el-col>.el-date-editor>.el-input__inner{
  padding-right: 0px;
  width: 130px;
  padding-right: 2px;
}
.el-form>.el-form-item{
  margin-bottom: 0px;
  margin-right: 5px;
}
.el-form-item__content{
  width: 150px;
}
.formItem3>.el-form-item__content{
  width: 280px;
}
.formItem4{
  margin-left: 30px;
}
.formItem4>.el-form-item__content{
  width: 80px;
}
.lib1Class{
  width: 260px;
}
.el-dropdown-link {
  cursor: pointer;
  font-size: 12px;
  margin-left: 9px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>