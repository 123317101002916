<template>
  <div>
    <div style="margin-bottom: 5px">
      <span>项目报表</span>
    </div>
    <el-card>
      <div slot="header">
        <el-form  :model="projectForm" :inline="true" style="background-color:#fafafa;">
          <el-form-item label="起始日期">
            <el-col :span="11">
              <el-date-picker class="date1" type="date" placeholder="起始日期" v-model="projectForm.date1" style="width: 100%;"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="结束日期">
            <el-col :span="11">
              <el-date-picker class="date2" type="date" placeholder="结束日期" v-model="projectForm.date2" style="width: 100%;"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="生产类型" style="margin-left: 0">
            <el-select v-model="projectForm.type" placeholder="请选择">
              <el-option v-for="(item,index) in this.typeOption" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="float: right;width: 50px">
            <el-button type="info"  style="padding: 2px 4px 2px 4px" icon="el-icon-download"></el-button>
            <el-button type="info"    icon="el-icon-document-copy"></el-button>
          </el-form-item>
        </el-form>


      </div>
      <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%">
        <el-table-column
            prop="id"
            label="项目ID">
        </el-table-column>
        <!--          项目名称-->
        <el-table-column
            prop="type"
            label="生产类型">
        </el-table-column>
        <el-table-column
            label="字数(原文/加权)">
          <template slot-scope="scope">
            {{ scope.row.wordNum }}/{{scope.row.powNum}}</template>
        </el-table-column>
        <el-table-column
            prop="member"
            label="参与译员">
        </el-table-column>
        <el-table-column
            prop="date"
            label="交付时间">
          <template slot-scope="scope">{{ scope.row.date }}</template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "ProjectTable",
  data(){
    return{
      //typeOption
      typeOption:[
        {
          value:'翻译',
          label:'翻译'
        },
        {
          value:'test',
          label:'test'
        },
      ],
      //提交的表单选项
      projectForm:{
        date1:'',
        date2:'',
        type:'',
      },
      //进行中项目个数
      progressCount:121,
      //超期项目个数
      outTimeCount:21,
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //表格所绑定的内容
      tableData:[
        {
          id:'20212121',
          type:"翻译",
          //原文字数
          wordNum:"2100",
          //加权字数
          powNum:"2093",
          //译员
          member:'xidian1234',
          date:"2021-11-15",
          },
      ],

    }
  },
  methods:{
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getVideoList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getVideoList()
    },
    //分配操作
    handleDistri(){

    }
  }
}
</script>

<style>
.el-card>.el-card__header{
  padding:20px 18px 6px 18px;
}
.el-card>.el-card__body{
  padding-top:6px;
}
.el-button + .el-button{
  padding: 2px 5px 2px 5px;
}
</style>