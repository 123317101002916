import { render, staticRenderFns } from "./LangTools.vue?vue&type=template&id=d2be3f4a&scoped=true&"
import script from "./LangTools.vue?vue&type=script&lang=js&"
export * from "./LangTools.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2be3f4a",
  null
  
)

export default component.exports