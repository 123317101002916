<template>
  <el-row class="row-bg" justify="center">
    <el-col :xl="6" :lg="7">
      <h2>欢迎使用MOOC视频处理系统</h2>
      <el-image :src="require('@/assets/mooc.jpg')" style="height: 180px;width: 180px"></el-image>
      <p>西安电子科技大学</p>
    </el-col>
    <el-col :span="1">
      <el-divider direction="vertical"></el-divider>
    </el-col>
    <el-col :xl="6" :lg="7">
      <el-form :model="registerForm" :rules="rules" ref="registerForm" label-width="100px" class="demo-registerForm">
        <el-form-item label="用户名" prop="username" style="width: 380px;">
          <el-input v-model="registerForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密 码" prop="password" style="width: 380px;">
          <el-input v-model="registerForm.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="passwordTwice" style="width: 380px;">
          <el-input v-model="registerForm.passwordTwice" type="password"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code" style="width: 380px;">
          <el-input v-model="registerForm.code" style="width: 112px; float: left" maxlength="5"></el-input>
          <el-image :src="captchaImg" class="captchaImg" @click="getCaptcha"></el-image>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="padding: 10px;padding-left: 20px;padding-right: 20px;">
            <router-link to="login">
            登录
            </router-link>
          </el-button>
          <el-button type="info" @click="submitForm('registerForm')" style="padding: 10px;padding-left: 20px;padding-right: 20px;">
            <router-link to="register">
              注册
            </router-link>
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import qs from 'qs'

export default {
  name: "Register",
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      registerForm: {
        username: '',
        password: '',
        passwordTwice:'',
        code: '',
        key: ''
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        passwordTwice: [
          {validator:validatePass2, trigger: 'blur'},
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {min: 5, max: 5, message: '长度为 5 个字符', trigger: 'blur'}
        ]
      },
      captchaImg: null
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post('/user/register',this.registerForm)
              .then(res => {
                // const jwt = res.headers['authorization']
                // this.$store.commit('SET_TOKEN', jwt)
                this.$router.push("/home")
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // resetForm(formName) {
    //   this.$refs[formName].resetFields();
    // },
    getCaptcha() {
      this.$axios.get('/user/captcha').then(res => {
        this.registerForm.key = res.data.data.key
        this.captchaImg = res.data.data.captchaImg
        this.registerForm.code = ''
      })
    }
  },
  created() {
    this.getCaptcha()
  }
}
</script>

<style scoped>
.el-row {
  background-color: #fafafa;
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.el-divider {
  height: 200px;
}

.captchaImg {
  float: left;
  margin-left: 8px;
  border-radius: 4px;
}
</style>