<template>
  <div>
    <div style="display: flex;justify-content: space-between">
      <span>子账号列表</span>
      <div>
<!--        <el-button  type="primary" plain-->
<!--                    @click="dialogFormVisible2=true"-->
<!--                    style="color: #5556E5;padding: 7px 15px 7px 15px">导入译员</el-button>-->
        <el-button  type="primary" plain
                    @click="dialogFormVisible=true"
                    style="color: #5556E5;padding: 7px 15px 7px 15px">生成子账号</el-button>
      </div>

    </div>
    <el-card>
      <el-form  :model="groupForm" :inline="true" ref="groupFormRef" style="margin-bottom: 15px;">
        <el-form-item label="账号名" prop="loginName">
          <el-input v-model="groupForm.loginName"  placeholder="请输入账号名"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="groupForm.name"  placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item class="formItem4">
          <el-button
              type="primary" plain
              style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
              @click="getGroupMemberList"
          >
            搜索</el-button>
          <el-button
              @click="resetGroupForm"
              type="primary" plain
              style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
          >
            重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-table
            border
            :data="tableData"
            style="width: 100%">
          <!--          项目名称-->
          <el-table-column
              prop="loginName"
              label="账号名">
          </el-table-column>
          <el-table-column
              prop="username"
              label="姓名"
              width="70px">
          </el-table-column>
          <el-table-column
              label="角色">
            <template slot-scope="scope">
              <el-tag plain>
                {{scope.row.role}}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
              prop="password"
              label="密码"
              >
          </el-table-column>
          <el-table-column
              prop="email"
              label="电子邮箱"
              >
          </el-table-column>
          <el-table-column
              prop="phone"
              label="手机号码"
              >
          </el-table-column>
          <el-table-column
              prop="modifiedTime"
              label="创建时间">
          </el-table-column>
          <el-table-column
              prop="lastLoginTime"
              label="最近登录"
              width="100px">
          </el-table-column>
          <el-table-column
              label="操作">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)"
                         type="text" size="small"
                         style="color: #5556E5;">编辑</el-button>
              <el-button @click="handleDelete(scope.row)"
                         type="text" size="small"
                         style="color: #5556E5;">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
    <el-dialog title="添加成员" :visible.sync="dialogFormVisible" width="500px">
      <el-form :model="countForm" :rules="dialogFormRules">
        <el-form-item class="dialogItem"  prop="count" label="请填写生成子账号数量" label-width="160px" label-position="left">
          <el-input v-model="countForm.count"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
            type="primary" plain
            style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
            @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" plain
                   style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
                   @click="dialogFormVisible = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导入译员" :visible.sync="dialogFormVisible2" width="500px">
      <el-form :model="transForm">
        <el-form-item label="译员文件" prop="selectFile" label-width="80px" label-position="left" class="item">
          <el-upload
              :show-file-list="false"
              action="https://jsonplaceholder.typicode.com/posts/">
            <el-button  type="info" plain
                        style="color: #000000;padding: 7px 15px 7px 15px">
              上传文档
            </el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button
                     type="text" size="small"
                     style="color: #5556E5;margin-left: 90px">模板下载</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
            type="primary" plain
            style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
            @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" plain
                   style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
                   @click="dialogFormVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MyGroupmember",
  data(){
    const checkNum = (rule, value, callback) => {
          if (!value) {
            return callback(new Error('输入不能为空'));
          }
          setTimeout(() => {
            if (!Number.isInteger(Number(value))) {
              console.log(typeof value)
              callback(new Error('请输入数字值'));
            } else {
              if (value < 1) {
                callback(new Error('输入数据必须大于1'));
              } else {
                callback();
              }
            }
          }, 1000);
        };
    return{
      dialogFormRules:{
        count:[
          {validator:checkNum,trigger:'blur'}
        ]
      },

      //子账号表单
      countForm:{
        count:''
      },
      //导入议员表单
      transForm:{

      },
      //控制对话框的弹出
      dialogFormVisible:false,
      //控制对话框的弹出
      dialogFormVisible2:false,
      //groupForm
      groupForm:{
       loginName:'',
        name:'',
      },
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //表格所绑定的内容
      tableData:[
        // {
        //   loginName:'xidian1234',
        //   name:"张三丰",
        //   role:[
        //       {
        //         type:'',label:'译员'
        //       },
        //     {
        //       type:'success',label:'项目管理员'
        //     },
        //   ],
        //   password:"123456",
        //   email:"123456@qq.com",
        //   phone:'15868833333',
        //   modifiedTime:"2021-09-07",
        //   lastTime:'2021-09-09',
        // },
        // {
        //   loginName:'xidian2234',
        //   name:"张二丰",
        //   role:[
        //     {
        //       type:'',label:'译员'
        //     },
        //   ],
        //   password:"345678",
        //   email:"654321@qq.com",
        //   phone:'15868822222',
        //   modifiedTime:"2021-09-08",
        //   lastTime:'2021-09-09',
        // },
      ],

      //checkbox复选框按钮
      multipleSelection: []
    }
  },
  created() {
    this.getGroupMemberList()
  },
  methods:{
    //重置langForm表单
    resetGroupForm(){
      //console.log(this.$refs)
      console.log("执行了重置方法")
      this.$refs.groupFormRef.resetFields();
      this.getGroupMemberList()
    },
    //更新团队成员数量
    async updateMemberCount(){
      const {data:result} = await this.$axios.post('/group/updatemembercount')
      console.log("0000000000000000000000000")
      console.log('result:',result)
      if(result.code!==200)
        return this.$message.error('更新团队成员数量失败')
    },
    //根据当前登录用户获取所在团队列表
    async getCurrentGroupList(){
      const {data:result} = await this.$axios.post('/group/currentgrouplist')
      console.log("Aaaaaaaaaaaaaaaaaa")
      if(result.code!==200)
        return this.$message.error('获取团队列表失败')
    },
    //获得用户（团队成员列表）
    getGroupMemberList(){
        this.getCurrentGroupList().then(async ()=>{
          const {data:result} =   await this.$axios.post("/group/groupmember",{
            currentPage:this.currentPage,
            pageSize:this.size,
            loginName:this.groupForm.loginName,
            username:this.groupForm.name,
            uid:this.$store.getters.GET_UID
          })
          console.log('result:',result.data.data)
          if(result.code!==200)
            return this.$message.error('获取项目列表失败')
          this.tableData = result.data.data;
          this.total = result.data.total
        })
    },
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getGroupMemberList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getGroupMemberList()
    },
    //编辑
    handleEdit(){

    },
    //删除
    handleDelete(){

    },
  }
}
</script>

<style>
.el-card>.el-card__header{
  padding:20px 18px 6px 18px;
}
.el-card>.el-card__body{
  padding-top:6px;
}
.el-button + .el-button{
  padding: 2px 5px 2px 5px;
}
.dialogItem > .el-form-item__content{
  width: 300px;
}
</style>