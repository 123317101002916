<template>
  <el-container>
    <el-header style="height: 44px">
      <div>
        <el-image :src="require('@/assets/xidian.jpg')" alt=""
                  fit="contain"></el-image>
      </div>
      <strong>欢迎使用XDMOOC智能处理系统</strong>
      <div >
        <!-- <el-avatar size="medium" :src="userInfo.avatar"></el-avatar>
        <el-dropdown> -->
              <!-- <span class="el-dropdown-link"> -->
                姓名：{{userInfo.cn}}
              <!-- </span> -->
          <!-- <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->
        <!-- <button @click="logout">退出</button> -->
      </div>
      
      </el-header>
    <div>
      <el-divider></el-divider>
    </div>


    <el-container>
      <SideMenu></SideMenu>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SideMenu from "@/views/inc/SideMenu";

export default {
  name: "Home",
  components: {
    SideMenu
  },
  data() {
    return {
      userInfo: {
        id: "",
        username: "",
        avatar: "",
        cn:""
      }
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      this.$axios.get("/user/userinfo")
          .then(res => {
            this.userInfo = res.data.data
            this.userInfo.username = sessionStorage.getItem("username")
            this.userInfo.cn = sessionStorage.getItem("cn")
          })
          .catch(error => {
            alert("请求用户信息失败")
            this.$router.push("/auth")
          })
    },
    logout() {
              window.location.href = "https://ids.xidian.edu.cn/personalInfo/logout"
          },
  }
}
</script>

<style scoped>
.el-image{
  width:200px;
  height: 50px;
  margin-top: 21px;
  text-align: center;
}

.header-avatar {
  float: right;
  width: 110px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.el-dropdown-link {
  cursor: pointer;
}

.el-container {
  margin: 0;
  padding: 0;
  height: 100vh;
}

.el-header {
  display: flex;
  background-color: #fff;
  color: #000;
  text-align: center;
  line-height: 60px;
  justify-content: space-between;
  align-items: center;
}

.el-aside {
  background-color: #D3DCE6;
  color: #333;
  line-height: 200px;
}

.el-main {
  background-color: #fff;
  color: #000;
  /*text-align: center;*/
  /*//line-height: 160px;*/
}

a {
  text-decoration: none;
}
.el-divider--horizontal{
  margin: 0;
  padding: 0;
  font-size: 0;
}

</style>