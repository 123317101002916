import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "../views/Login"
import Home from "../views/Home"
import Index from "../views/Index.vue"
import Auth from "../views/Auth.vue"
import Videolist from "@/views/video/Videolist";
import Videoproc from "@/views/video/Videoproc";
import Userinfo from "@/views/user/Userinfo";
import Playvideo from "@/views/player/Playvideo";
import Register from "@/views/Register"
import Myproject from "@/views/project/Myproject";
import Myquestions from "@/views/questions/Myquestions";
import Transvideo from "@/views/mainpage/Transvideo";
import LangLib from "@/views/language/LangLib";
import LangLibSearch from "@/views/language/LangLibSearch";
import MajorLangLib from "@/views/language/MajorLangLib";
import MajorLangLibSearch from "@/views/language/MajorLangLibSearch";
import MyGroupList from "@/views/group/MyGroupList";
import MyGroupmember from "@/views/group/MyGroupmember";
import ProjectWatcher from "@/views/data/ProjectWatcher";
import ProjectCheckTable from "@/views/data/ProjectCheckTable";
import ProjectTable from "@/views/data/ProjectTable";
import ProjectTransTable from "@/views/data/ProjectTransTable";
import CheckTools from "@/views/tools/CheckTools";
import LangTools from "@/views/tools/LangTools";
import MajorLangTools from "@/views/tools/MajorLangTools";
import NewMLib from "@/views/language/NewMLib";
import NewGroup from "@/views/group/NewGroup";
import NewLLib from "@/views/language/NewLLib";
import QualityCheck from "@/views/tools/QualityCheck";

import MyManaProject from "@/views/project/MyManaProject";

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        component: Home,
        children: [
            {
                path: '/',
                redirect: {
                    name: 'Index'
                }
            },
            {
                path: '/index',
                name: 'Index',
                component: Index
            },
            {
                path: '/video/videolist',
                name: 'Videolist',
                component: Videolist
            },
            {
                path: '/video/videoproc',
                name: 'Videoproc',
                component: Videoproc
            },
            {
                path: '/user/userinfo',
                name: 'Userinfo',
                component: Userinfo
            },
            //我的项目界面开始
            {
                path:'/project/myproject',
                name:'Myproject',
                component: Myproject
            },
            {
                path:'/project/mymanaproject',
                name:'MyManaProject',
                component: MyManaProject
            },
            //我的项目界面结束
            //语言资产界面开始
            {
                path:'/lang/langlib',
                name:'LangLib',
                component: LangLib
            },
            {
                path:'/lang/langlibsearch',
                name:'LangLibSearch',
                component: LangLibSearch
            },
            {
                path:'/lang/majorlanglib',
                name:'MajorLangLib',
                component: MajorLangLib
            },
            {
                path:'/lang/majorlanglibsearch',
                name:'MajorLangLibSearch',
                component: MajorLangLibSearch
            },
            //语言资产结束
            //我的团队开始
            //我的团队开始
            {
                path:'/group/mygrouplist',
                name:'MyGroupList',
                component: MyGroupList
            },
            {
                path:'/group/mygroupmember',
                name:'MyGroupmenber',
                component: MyGroupmember
            },
            //我的团队结束
            //数据报表开始
            {
                path:'/data/projectwatcher',
                name:'ProjectWatcher',
                component: ProjectWatcher
            },
            {
                path:'/data/projectchecktable',
                name:'ProjectCheckTable',
                component: ProjectCheckTable
            },
            {
                path:'/data/projecttable',
                name:'ProjectTable',
                component: ProjectTable
            },
            {
                path:'/data/projecttranstable',
                name:'ProjectTransTable',
                component: ProjectTransTable
            },
            //数据报表结束
            //工具开始
            {
                path:'/tools/checktools',
                name:'CheckTools',
                component: CheckTools
            },
            {
                path:'/tools/langtools',
                name:'LangTools',
                component: LangTools
            },
            {
                path:'/tools/majorlangtools',
                name:'MajorLangTools',
                component: MajorLangTools
            },
            //g工具结束
            //疑问开始
            {
                path:'/questions/myquestions',
                name:'Myquetions',
                component: Myquestions
            },
            {
                path:'/mainpage/transvideo',
                name:"Transvideo",
                component: Transvideo
            },
            //新建术语库界面
            {
                path:'/language/newmlib',
                name:"NewMLib",
                component: NewMLib
            },
            //新建语料库界面
            {
                path:'/language/newllib',
                name:"NewL  Lib",
                component: NewLLib
            },
            //新建团队界面
            {
                path:'/group/newgroup',
                name:"NewGroup",
                component: NewGroup
            },
            //质量检查页面
            {
                path:'/tools/qualitycheck',
                name:"QualityCheck",
                component: QualityCheck
            },
            // //我的设置界面
            // {
            //     path: '/settings',
            //     name:"Settings",
            //     component: Settings
            // },
        ]
    },
    {
        path: '/',
        redirect: Auth
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Auth
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/player/playvideo',
        name: 'Playvideo',
        component: Playvideo
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

//路由控制守卫
router.beforeEach(function(to, from, next) {

    if (!sessionStorage.getItem("token")) {
        if (to.path ==='/player/playvideo'){

        }
        else if (to.path !== '/auth') {
            return next('/auth')
        }
    }
    if(to.path === '/login'){
        if (sessionStorage.getItem("cn") !== "admin"){
            return next('/home')
        }
    }
    // if(sessionStorage.getItem("cn")!=="SuperAdmin"){
    //     return next('/home')
    // }
    next()
})
//路由控制守卫
// router.beforeEach((to,from,next)=>{
//     if(to.path==='/'||to.path==='/auth'){
//       next();
//     }else{
//     }
  
//   })
export default router
