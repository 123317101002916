import axios from "axios";
import router from "./router";
import Element from "element-ui"


// axios.defaults.baseURL = "http://101.34.231.172:81/api"  //这里使用nginx反向代理/api定位到后端接口，如果是开发环境删掉api即可
//  axios.defaults.baseURL = "//aimooc.cvnis.net/api/"
//注意修改videoproc.vue里面的action url!!!!
//***********************************************
const request = axios.create({

    headers: {
        'Content-Type': "application/json; charset=utf-8",
    }
})

request.interceptors.request.use(config => {
    config.headers['satoken'] = sessionStorage.getItem("token")
    return config
})

request.interceptors.response.use(
    response => {
        let res = response.data

        if (res.code === 200) {
            return response
        } else if(res.code === 5002){
            Element.Message.error(!res.msg ? '密码输入错误' : res.msg)
            return Promise.reject(response.data.msg)
        }
        else if(res.code === 7002) {
            Element.Message.error(!res.msg ? '密码输入错误' : res.msg)
            return Promise.reject(response.data.msg)
        }
        else{
            return response
            // Element.Message.error(!res.msg ? '系统异常' : res.msg)
            // return Promise.reject(response.data.msg)
        }
    },
    error => {

        console.log(error)

        if (error.response.data) {
            error.massage = error.response.data.msg
        }

        // if (error.response.status === 401) {
        //     router.push("/auth")
        // }

        Element.Message.error(error.massage, {duration: 3000})
        return Promise.reject(error)
    }
)

export default request