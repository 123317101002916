<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path:'/tools/checktools' }">质检工具</el-breadcrumb-item>
      <el-breadcrumb-item>质量检查</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="margin-top: 10px;">
      <el-form :model="qualityCheckForm">
        <el-form-item label="质检类型" prop="type" required label-position="left" label-width="110px">
          <el-input v-model="qualityCheckForm.type" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="双语文档" prop="document" required label-position="left" label-width="110px" style="margin-top: 7px">
          <el-upload
            :show-file-list="false"
            action="https://jsonplaceholder.typicode.com/posts/">
          <el-button  type="primary" plain
                      style="color: #000000;padding: 7px 15px 7px 15px;margin-right: 500px">
            上传文档
          </el-button>
          <div slot="tip" style="width: 500px;margin: 0;padding: 0;height: 20px;line-height: 15px">
            <span style="float: left;font-size: 10px;margin: 0;padding: 0">
              支持TMX或excel格式【<el-button
                type="text" size="small"
                style="color: #5556E5;">模板下载</el-button>】格式文件,且文件小于10M
            </span>
          </div>
        </el-upload>
        </el-form-item>
        <el-form-item label="源语言" style="margin-left: 0;margin-bottom: 7px" required label-position="left" label-width="110px" prop="lang">
          <el-select v-model="qualityCheckForm.origLang" placeholder="请选择">
            <el-option v-for="(item,index) in this.origLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目标语言" style="margin-left: 0;margin-bottom: 7px" required label-position="left" label-width="110px" prop="lang">
          <el-select v-model="qualityCheckForm.targLang" placeholder="请选择">
            <el-option v-for="(item,index) in this.targLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="检查配置规则" style="margin-left: 0;width: 2000px" required label-position="left" label-width="110px" prop="rules">
            <el-card style="width: 800px" class="menuCard">
              <div slot="header" class="cardHeader">
                <el-menu :default-active="activeIndex" class="settingMenu" mode="horizontal" @select="handleSelect">
                  <el-menu-item class="settingMenu1" index="1">INCONSISTENCE</el-menu-item>
                  <el-menu-item index="2">CONTENT</el-menu-item>
                  <el-menu-item index="3">FORMAT</el-menu-item>
                  <el-menu-item index="4">TAG</el-menu-item>
                  <el-menu-item index="5">CUSTOM</el-menu-item>
                  <el-menu-item index="6">TERMINOLOGY</el-menu-item>
                </el-menu>
              </div>
              <!--            index===1时-->
              <div v-if="activeIndex==='1'">
                <h2>INCONSISTENCE界面</h2>
              </div>
              <!--            index===2时-->
              <div v-if="activeIndex==='2'">
                <h2>CONTENT</h2>
              </div>
              <!--            index===3时-->
              <div v-if="activeIndex==='3'">
                <h2>FORMAT</h2>
              </div>
              <!--            index===4时-->
              <div v-if="activeIndex==='4'">
                <h2>TAG</h2>
              </div>
              <!--            index===5时-->
              <div v-if="activeIndex==='5'">
                <h2>CUSTOM</h2>
              </div>
              <!--            index===6时-->
              <div v-if="activeIndex==='6'">
                <h2>TERMINOLOGY</h2>
              </div>
            </el-card>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "QualityCheck",
  data(){
    return{
      //控制导航栏
      activeIndex:'1',
      qualityCheckForm:{
        //质检类型
        type:'',
        //双语文档
        document:'',
        //源语言
        origLang:'',
        targLang:'',
        //配置规则
        rules:''
      },
      //源语言选项
      origLangOption:[
        {
          label:'中文',
          value:'中文'
        },
        {
          label:'英文',
          value:'英文'
        },
      ],
      //目标语言选项
      targLangOption:[
        {
          label:'中文',
          value:'中文'
        },
        {
          label:'英文',
          value:'英文'
        },
      ],
    }
  },
  methods:{
    //自定义设置界面的导航点击函数
    handleSelect(index){
      this.activeIndex = index
      // console.log(this.activeIndex)
    }
  }
}
</script>

<style>
.menuCard>.el-card__header{
  padding: 0;

}
.settingMenu> .el-menu-item{
  padding: 0 10px 0 10px;
}
</style>