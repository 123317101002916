<template>
  <div>
    <div style="display: flex;justify-content: space-between">
      <span>我参与的项目</span>
      <el-button  type="primary" plain
                  style="color: #5556E5;padding: 7px 15px 7px 15px"
                  icon="el-icon-refresh-right"></el-button>
    </div>
    <el-card>
      <div slot="header" class="clearfix1" v-if="flag">
        <el-input placeholder="请输入项目名称" v-model="projectInput" style="width: 340px;">
          <el-button @click="getProjectList" slot="append" icon="el-icon-search" style="padding-left: 14px"></el-button>
        </el-input>
        <el-button type="text">
          <span class="el-dropdown-link"
                @click="flag=false"
                style=" color: #5556E5;
                        margin-left: 14px">
                高级搜索
                <i class="el-icon-arrow-down el-icon--right"
                   style="margin-left: 2px"></i>
          </span>
        </el-button>
      </div>
      <div slot="header" class="clearfix1" v-else>
        <el-input placeholder="请输入项目名称" v-model="projectInput" style="width: 340px">
          <el-button @click="getProjectList" slot="append" icon="el-icon-search" style="padding-left: 14px"></el-button>
        </el-input>
        <el-button type="text">
          <span class="el-dropdown-link"
                @click="flag=true"
                style=" color: #5556E5;
                        margin-left: 14px">
                高级搜索
                <i class="el-icon-arrow-up el-icon--right"
                   style="margin-left: 2px"></i>
          </span>
        </el-button>
        <el-form  :model="projectForm" ref="projectFormRef" :inline="true" style="background-color:#fafafa;margin-top: 4px">
          <el-form-item label="源语言" prop="origLang">
            <el-select v-model="projectForm.origLang" placeholder="请选择">
              <el-option v-for="(item,index) in this.origLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="目标语言" prop="targLang" style="margin-left: 0">
            <el-select v-model="projectForm.targLang" placeholder="请选择">
              <el-option v-for="(item,index) in this.targLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目状态" prop="status">
            <el-select v-model="projectForm.status" placeholder="请选择">
              <el-option v-for="(item,index) in this.statusOption" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标签" prop="label" style="margin-left: 0">
            <el-select v-model="projectForm.label" placeholder="请选择">
              <el-option v-for="(item,index) in this.labelOption" :key="index" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="formItem4" style="margin-left: 900px">
            <el-button
                @click="getProjectList"
                type="primary" plain
                style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
            >
              搜索</el-button>
            <el-button
                @click="resetForm"
                type="primary" plain
                style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
            >
              重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <template>
          <el-popconfirm title="确定删除?" @confirm="toggleSelectionDelete()">
            <el-button
                :disabled="disabled"
                type="primary" plain
                slot="reference"
                style="color: #5556E5;padding: 7px 15px 7px 15px;border: 1px solid;margin-right: 2px">
              批量删除
            </el-button>
          </el-popconfirm>
          <el-button
              :disabled="disabled"
              type="primary" plain
              style="color: #5556E5;padding: 7px 15px 7px 15px;width: 88px;height: 30px;border: 1px solid"
              @click="toggleSelectionDistri()">
            批量分发</el-button>
        </template>
        <el-table
            border
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange">
          <!--          复选框column-->
          <el-table-column
              type="selection">
          </el-table-column>
          <!--          项目名称-->
          <el-table-column
              prop="name"
              label="项目名称">
          </el-table-column>
<!--          <el-table-column-->
<!--              prop="resource"-->
<!--              label="环节">-->
<!--          </el-table-column>-->
          <el-table-column
              prop="status"
              label="状态">
          </el-table-column>
<!--          <el-table-column-->
<!--              prop="progress"-->
<!--              label="进度"-->
<!--              width="100">-->
<!--          </el-table-column>-->
          <el-table-column
              prop="language"
              label="语向">
          </el-table-column>
          <el-table-column
              prop="wordNum"
              label="原始字数">
          </el-table-column>
          <el-table-column
              prop="sentNum"
              label="句对数">
          </el-table-column>
          <el-table-column
              prop="date"
              label="创建日期">
            <template slot-scope="scope">{{ scope.row.date }}</template>
          </el-table-column>
          <el-table-column
              label="操作">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)"
                         type="text" size="small"
                         style="color: #5556E5;">翻译</el-button>
              <el-button @click="handleEdit(scope.row)"
                         type="text" size="small"
                         style="color: #5556E5;">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Myroject",
  data(){
    return{
      //源语言选项
      origLangOption:[
        {
          label:'中文',
          value:'中文'
        },
        {
          label:'英文',
          value:'英文'
        },
      ],
      //目标语言选项
      targLangOption:[
        {
          label:'中文',
          value:'中文'
        },
        {
          label:'英文',
          value:'英文'
        },
      ],
      //状态选项
      statusOption:[
        {
          label:'已完成',
          value:'已完成'
        },
        {
          label:'未翻译',
          value:'未翻译'
        },
        {
          label:'进行中',
          value:'进行中'
        },
      ],
      //标签选项
      labelOption:[
        {
          label:'机器学习',
          value:'机器学习'
        },
        {
          label:'语音识别',
          value:'语音识别'
        },
      ],
      projectForm:{
        origLang:'',
        targLang:'',
        status:'',
        label:''
      },
      //控制高级搜索的展开
      flag:true,
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //删除 分发按钮是否可用
      disabled: true,
      //input搜索框绑定的内容
      projectInput:'',
      //表格所绑定的内容
      tableData:[
        {name:"行列式的性质1",
          resource:"本人创建",
          status:"已完成",
          progress:"100%",
          language:"中文-英文",
          wordNum:"2100",
          sentNum:"210",
          date:"2021-11-15",
          operator:"翻译"},
      ],

      //checkbox复选框按钮
      multipleSelection: []
    }
  },
  methods:{
    //获取项目列表
    async getProjectList(){
      const {data:result} = await this.$axios.post("/project/projectlist",{
        uid:this.$store.getters.GET_UID,
        currentPage:this.currentPage,
        pageSize:this.size,
        projectName:this.projectInput,
        origLang:this.projectForm.origLang,
        targLang:this.projectForm.targLang,
        status:this.projectForm.status,
        label:this.projectForm.label,
        username:this.$store.getters.GET_USERNAME
      })
      console.log('result:',result)
      if(result.code!==200)
        return this.$message.error('获取项目列表失败')
      this.tableData = result.data.data.records;
      this.total = result.data.data.total

    },
    //批量删除功能
    toggleSelectionDelete(row) {
      //有参数代表的是针对的具体的行 可认为是删除功能
      const rows = []
      if (row) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
          console.log("aaaaa")
        });
      } //可认为是批量删除功能
      else {
        this.$refs.multipleTable.clearSelection();
        console.log("bbbbbbbbbbb")
      }
    },
    //批量分发功能
    toggleSelectionDistri(row) {
      const rows = []
      if (row) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.disabled = this.multipleSelection == 0
      // console.log(this.multipleSelection)
    },
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getVideoList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getVideoList()
    },
  }
}
</script>

<style>
.el-card>.el-card__header{
  padding:20px 18px 6px 18px;
}
.el-card>.el-card__body{
  padding-top:6px;
}
.el-button + .el-button{
  padding: 2px 5px 2px 5px;
}
</style>