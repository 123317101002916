<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path:'/lang/langlib' }">语料库管理</el-breadcrumb-item>
      <el-breadcrumb-item>新建语料库</el-breadcrumb-item>
      <!--      <el-breadcrumb-item>活动列表</el-breadcrumb-item>-->
      <!--      <el-breadcrumb-item>活动详情</el-breadcrumb-item>-->
    </el-breadcrumb>
    <el-card class="box" style="margin-top: 10px;">
      <div slot="header" class="clearfix1" style="padding: 0px">
        <span>新建语料库</span>
      </div>
      <el-form :model="libForm" class="libFormClass" :show-message="false">
        <el-form-item label="语料库名称" prop="name" required label-position="left" label-width="100px">
          <el-input v-model="libForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="领域" style="margin-left: 0" required label-position="left" label-width="100px" prop="field">
          <el-select v-model="libForm.field" placeholder="请选择" >
            <el-option v-for="(item,index) in this.fieldOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="源语言" style="margin-left: 0" required label-position="left" label-width="100px" prop="lang">
          <el-select v-model="libForm.origLang" placeholder="请选择">
            <el-option v-for="(item,index) in this.origLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目标语言" style="margin-left: 0" required label-position="left" label-width="100px" prop="lang">
          <el-select v-model="libForm.targLang" placeholder="请选择">
            <el-option v-for="(item,index) in this.targLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 80px">
          <el-button
              type="primary" plain
              style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
          >
            创建</el-button>
          <el-button
              type="primary" plain
              style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
          >
            取消</el-button>
        </el-form-item>
        <el-form>

        </el-form>
      </el-form>
    </el-card>

  </div>
</template>

<script>
export default {
  name: "NewMLib",
  data() {
    return{
      //fieldOption 领域的选项
      fieldOption:[
        {
          label:'人工智能',
          value:'人工智能'
        },
        {
          label:'计算机',
          value:'计算机'
        },
      ],
      //源语言选项
      origLangOption:[
        {
          label:'中文',
          value:'中文'
        },
        {
          label:'英文',
          value:'英文'
        },
      ],
      //目标语言选项
      targLangOption:[
        {
          label:'中文',
          value:'中文'
        },
        {
          label:'英文',
          value:'英文'
        },
      ],
      //新建语料库表单
      libForm:{
        name:'',
        field:'',
        origLang:'',
        targLang:'',

      },
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style>
.box>.el-card__header{
  padding: 10px 20px 10px 20px;
}
.box>.el-card__body{
  height: 380px;
  padding-top: 10px;
}
.libFormClass>.el-form-item>.el-form-item__content{
  width: 210px;
}
.libFormClass>.el-form-item>.el-form-item__content>.el-select{
  width: 210px;
}
.libFormClass>.el-form-item{
  margin-bottom: 10px;
}

</style>