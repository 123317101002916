<template>
  <div>
      <video :src="playaddress"
             controls
              style="width: 100%;height: 100vh">
      </video>
<!--   <div>-->
<!--      &lt;!&ndash;     <button @click="play">播放视频</button>&ndash;&gt;-->
<!--      <div class="prism-player" id="player-con"></div>-->
<!--    </div> -->
<!--    <video width="320" height="240" controls>-->
<!--      <source :src="playaddress" type="video/mp4">-->
<!--      <source :src="playaddress" type="video/ogg">-->
<!--      您的浏览器不支持 HTML5 video 标签。-->
<!--    </video>-->
  </div>
</template>

<script>
export default {
  name: "Playvideo.vue",
  data() {
    return {
      playaddress: this.$route.query.playaddress
    }
  },
  methods: {},

  mounted() {
    console.log(this.playaddress)
    // var player = new Aliplayer({
    //   "id": "player-con",
    //   //"source":"https://video.ailemon.net/sv/24ec11a9-17d93b9258f/24ec11a9-17d93b9258f.mp4",
    //   "source": this.playaddress,
    //   //"source":"https://ailemon-storage.oss-cn-shanghai.aliyuncs.com/test1/e9d12aea-0985-4dd6-a076-279be0304042_output.mp4",
    //   //"source": "https://outin-946fd2d1dd5f11eb8e9300163e0eb78b.oss-cn-beijing.aliyuncs.com/sv/2a3fe8c2-17a7c45ecbd/2a3fe8c2-17a7c45ecbd.mp4?Expires=1625586308&OSSAccessKeyId=LTAI4FocoL6tuCdYhuvug6Ee&Signature=r1LOKTaHufQh71JBtLKDP5Aidwc%3D",
    //   //"source":"https://my-mooc.oss-cn-beijing.aliyuncs.com/wx/test/test.mp4?Expires=1627453373&OSSAccessKeyId=TMP.3Khg4SxX2TcucJhcxCe7Ept755PE39J7aQ8HADD2F5eVpHJKU4DjRTCD85VjJaHmT1QccZgmrHHpnEtPs97Ue2SzBqT5FX&Signature=dbx%2Bez8YlStBs7f1D86QkJU2DP4%3D",
    //   // "vid": this.id,
    //   // "playAuth": this.playAuth,
    //   "width": "100%",
    //   "height": "500px",
    //   "autoplay": false,
    //   "isLive": false,
    //   "rePlay": false,
    //   "playsinline": true,
    //   "preload": true,
    //   "controlBarVisibility": "hover",
    //   "useH5Prism": true
    // }, function (player) {
    //   console.log("The player is created");
    // })
  }

  // created() {
  //   this.$axios.get('/eduvod/video/getPlayAuth'+`/${this.id}`)
  //       .then(res=>{
  //         console.log(this.playAuth)
  //         this.playAuth= res.data.data.playAuth;
  //         //console.log(this.playAuth)
  //       })
  //       .catch(error=>{
  //         alert("获取播放凭证失败")
  //       })
  // },

}
</script>

<style scoped>

</style>