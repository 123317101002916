<template>
  <div>
  <el-carousel height="500px" autoplay>
    <el-carousel-item v-for="item in pics" :key="item.url">
      <img :src="item.url"/>
    </el-carousel-item>
  </el-carousel>

  </div>
</template>

<script>
export default {
  name: "MyCarousel",
  data() {
    return {
      pics: [
        { url: require("../assets/loginCarousel/lbbg1.png") },
        { url: require("../assets/loginCarousel/lbbg2.png") },
        { url: require("../assets/loginCarousel/lbbg3.png") },
      ]
    };
  }
}
</script>

<style scoped>

</style>