import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: '',
        //侧边栏激活的index
        sideActiveIndex:'',
        //用户登录得到的用户uid
        uid:'',
        //用户学号或账号名
        username:'',
        //用户真实姓名
        cn:''
    },
    getters:{
        GET_INDEX:(state)=>{
            //初始时设置为0
            if (sessionStorage.getItem("sideActiveIndex")===null)
                return "0"
            else
                return sessionStorage.getItem("sideActiveIndex")
        },
        GET_UID:(state)=>{
            return sessionStorage.getItem("uid")
        },
        GET_USERNAME:(state)=>{
            return sessionStorage.getItem("username")
        },
        GET_CN:(state)=>{
            return sessionStorage.getItem("cn")
        }
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
            sessionStorage.setItem("token", token)
        },
        SET_INDEX:(state,sideActiveIndex)=>{
            state.sideActiveIndex = sideActiveIndex
            sessionStorage.setItem("sideActiveIndex",sideActiveIndex)
        },
        SET_UID:(state,uid)=>{
            state.uid = uid
            sessionStorage.setItem("uid",uid)
        },
        SET_USERNAME:(state,username)=>{
            state.username = username
            sessionStorage.setItem("username",username)
        },
        SET_CN:(state,cn)=>{
            state.cn = cn
            sessionStorage.setItem("cn",cn)
        },
        resetState: (state) => {
            state.token = ''
            state.sideActiveIndex = '0'
            state.uid = ''
            state.username = ''
            cn = ''
        }
    },
    actions: {},
    modules: {}
})
