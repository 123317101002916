<template>
  <div>
    <div style="display: flex;justify-content: space-between">
      <span>团队列表</span>
      <router-link to="/group/newgroup">
        <el-button  type="primary" plain
                    style="color: #5556E5;padding: 7px 15px 7px 15px">创建团队</el-button>
      </router-link>
    </div>
    <el-card>
      <el-form  :model="langForm" ref="langFormRef" :inline="true" style="margin-bottom: 10px;">
        <el-form-item label="源语言" prop="origLang">
          <el-select v-model="langForm.origLang" style="width: 124px" placeholder="请选择">
            <el-option v-for="(item,index) in this.origLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目标语言" prop="targLang">
          <el-select v-model="langForm.targLang" style="width: 124px" placeholder="请选择">
            <el-option v-for="(item,index) in this.targLangOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="领域" style="margin-left: 0" prop="field">
          <el-select v-model="langForm.field" placeholder="请选择">
            <el-option v-for="(item,index) in this.fieldOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="name">
          <el-input v-model="langForm.name" placeholder="请输入团队名称"></el-input>
        </el-form-item>
        <el-form-item class="formItem4">
          <el-button
              @click="getGroupList"
              type="primary" plain
              style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
          >
            搜索</el-button>
          <el-button
              type="primary" plain
              style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
              @click="resetLangForm"
          >
            重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-table
            border
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%">
          <!--          项目名称-->
          <el-table-column
              prop="groupName"
              label="团队名称">
          </el-table-column>
          <el-table-column
              prop="langDirection"
              label="语向">
          </el-table-column>
          <el-table-column
              prop="field"
              label="领域">
          </el-table-column>
          <el-table-column
              prop="memberCount"
              label="团队成员"
              width="100px">
          </el-table-column>
          <el-table-column
              prop="modifiedTime"
              label="创建时间">
          </el-table-column>
          <el-table-column
              prop="modifiedUser"
              label="创建人"
              width="100px">
          </el-table-column>
          <el-table-column
              label="操作">
            <template slot-scope="scope">
              <el-button @click="handleManage(scope.row)"
                         type="text" size="small"
                         style="color: #5556E5;">管理成员</el-button>
              <el-button @click="handleEdit(scope.row)"
                         type="text" size="small"
                         style="color: #5556E5;">编辑</el-button>
              <el-button @click="handleDelete(scope.row)"
                         type="text" size="small"
                         style="color: #5556E5;">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="pageSize"
            :total=total>
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "MyGroupList",
  data(){
    return{
      //源语言的选项
      origLangOption:[
        { label:'英文',
          value:'英文'},
        { label:'中文',
          value:'中文'},
      ],
      //目标语言的选项
      targLangOption:[
        { label:'英文',
          value:'英文'},
        { label:'中文',
          value:'中文'},
      ],
      //fieldOption 领域的选项
      fieldOption:[
        {
          label:'人工智能',
          value:'人工智能'
        },
        {
          label:'计算机',
          value:'计算机'
        },
      ],
      //langForm
      langForm:{
        origLang:"",
        targLang:'',
        name:'',
        field:'',
      },
      //分页相关
      currentPage: 1,
      total: 20,
      pageSize: 5,
      //表格所绑定的内容
      tableData:[
        // {group_name:"s1",
        //   field:"互联网与电子通信",
        //   member_count:"6",
        //   lang_direction:"中文-英文",
        //   modifiedTime:"2021-09-07",
        //   modifiedUser:"test",
        //   }
      ],

      //checkbox复选框按钮
      multipleSelection: []
    }
  },
  created() {
    this.getGroupList()
  },
  methods:{
    //重置langForm表单
    resetLangForm(){
      //console.log(this.$refs)
      console.log("执行了重置方法")
      this.$refs.langFormRef.resetFields();
      this.getGroupList()
    },
    //根据当前登录用户获取所在团队列表
    async getCurrentGroupList(){
      const {data:result} = await this.$axios.get('/group/currentgrouplist'+`/${this.$store.getters.GET_USERNAME}`)
      if(result.code!==200)
        return this.$message.error('获取团队列表失败')
    },
    //获取group列表
    async getGroupList(){
      const {data:result} = await this.$axios.post('/group/grouplist',
          {
                uid:this.$store.getters.GET_UID,
                currentPage:this.currentPage,
                pageSize:this.pageSize,
                origLang:this.langForm.origLang,
                targLang:this.langForm.targLang,
                name:this.langForm.name,
                field:this.langForm.field,
                username:this.$store.getters.GET_USERNAME})
      console.log('result:',result)
      if(result.code!==200)
        return this.$message.error('获取团队列表失败')
      this.tableData = result.data.data.records;
      this.total = result.data.data.total
    },
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getGroupList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getGroupList()
    },
    //管理成员
    handleManage(){

    },
    //编辑
    handleEdit(){

    },
    //删除
    async handleDelete(row){
      //console.log(row)
      // console.log("+++++++++++++++")
      // console.log(row)
      console.log(row.groupName)
      const {data:result} =await this.$axios.get("/group/removeitem"+`/${row.groupName}`)
      console.log(result)
      if(result.code!==200)
        return this.$message.error('删除数据失败')
      this.getGroupList();
    },
  }
}
</script>

<style>
.el-card>.el-card__header{
  padding:20px 18px 6px 18px;
}
.el-card>.el-card__body{
  padding-top:6px;
}
.el-button + .el-button{
  padding: 2px 5px 2px 5px;
}
</style>