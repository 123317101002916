import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"
import axios from './axios'
import Video from 'video.js'
import 'video.js/dist/video-js.css'


//引入iconfont
import './assets/icon/iconfont.css'


Vue.prototype.$axios = axios
Vue.use(Element)
//require("./mock") //引入mock数据，关闭则注释该行

axios.defaults.baseURL = "//aimooc.cvnis.net/api"
//  axios.defaults.baseURL = "http://127.0.0.1:8003"
// axios.defaults.baseURL = '/api/'  // api 即上面 vue.config.js 中配置的地址
axios.defaults.timeout = 300000 // 请求超时
Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
