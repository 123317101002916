<template>
  <div>
    <el-card>
      <el-form  :model="toolsForm" :inline="true" style="margin-bottom: 6px;">
        <el-form-item label="名称">
          <el-input v-model="toolsForm.name"  placeholder="项目名称">
          </el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="toolsForm.status"  placeholder="请选择">
            <el-option v-for="(item,index) in this.statusOption" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 10px">
          <el-button
              type="primary" plain
              style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid">
            搜索</el-button>
          <el-button
              type="primary" plain
              style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid">
            重置</el-button>
        </el-form-item>
        <!--        刷新按钮-->
        <el-form-item style="float: right;width: 42px">
          <el-button  type="primary" plain
                      style="color: #5556E5;padding: 7px 15px 7px 15px"
                      icon="el-icon-refresh-right"></el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button
            @click="dialogTableVisible = true"
            type="primary" plain
            style="margin-bottom: 6px;float: right;color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid">
          创建术语项目</el-button>
      </div>
      <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;margin-top: 10px;">
        <!--          项目名称-->
        <el-table-column
            prop="id"
            label="ID">
        </el-table-column>
        <el-table-column
            prop="name"
            label="项目名称">
        </el-table-column>
        <el-table-column
            prop="wordNum"
            label="词条数">
        </el-table-column>
        <el-table-column
            prop="language"
            label="语向">
        </el-table-column>
        <el-table-column
            prop="modifiedTime"
            label="创建日期">
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态">
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button @click="handleMore(scope.row)"
                       type="text" size="small"
                       style="color: #5556E5;">查看详情<i class="el-icon-arrow-down"></i></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20]"
            :page-size="size"
            :total=total>
        </el-pagination>
      </div>
      <el-dialog title="创建术语对齐项目" :visible.sync="dialogTableVisible" width="500px">
        <el-form :model="majorLangForm" :rules="majorLangFormRules" class="majorFormClass">
            <el-form-item label="项目名称" prop="name" label-width="80px" label-position="left" class="item">
              <el-input v-model="majorLangForm.name"></el-input>
            </el-form-item>
            <el-form-item label="语言方向" label-width="80px" label-position="left" style="margin-top: 5px">
              <div style="display: flex;justify-content: space-between">
                <el-form-item class="item2" :span="11">
                  <el-select v-model="majorLangForm.lang1" placeholder="源语言">
                    <el-option label="英文" value="英文"></el-option>
                    <el-option label="中文" value="中文"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item2" :span="11">
                  <el-select v-model="majorLangForm.lang2" placeholder="目标语言">
                    <el-option label="中文" value="中文"></el-option>
                    <el-option label="英文" value="英文"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item label="提取类型" prop="type" label-width="80px" label-position="left" class="item">
              <el-radio-group v-model="majorLangForm.type">
                <el-radio label="单语提取"></el-radio>
                <el-radio label="双语提取"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="选择文件" prop="selectFile" label-width="80px" label-position="left" class="item">
                <el-upload
                    :show-file-list="false"
                    action="https://jsonplaceholder.typicode.com/posts/">
                  <el-button  type="primary" plain
                              style="color: #000000;padding: 7px 15px 7px 15px">
                              <i class="el-icon-upload"></i>上传文件
                  </el-button>
                  <div slot="tip" class="el-upload__tip"
                      style="margin: 0;padding: 0">支持扩展名:docx,xlsx,txt,zip</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="词频" prop="count1" label-width="80px" label-position="left" class="countClass">
                <span style="margin-left: 0">
                  术语出现的频率最少为
                  <el-input v-model="majorLangForm.count1" class="countClass" style="width: 60px">
                    {{majorLangForm.count1}}
                  </el-input>
                  次
                </span>
            </el-form-item>
          <el-form-item label="词长限制" label-width="80px" label-position="left" class="countClass">
            <div>
              <span>
                  术语长度最小为
                  <el-input v-model="majorLangForm.count2" class="countClass" style="width: 60px">
                    {{majorLangForm.count2}}
                  </el-input>
                  个字/词
                </span>
            </div>
            <div>
              <span>
                  术语长度最大为
                  <el-input v-model="majorLangForm.count3" class="countClass" style="width: 60px">
                    {{majorLangForm.count3}}
                  </el-input>
                  个字/词
                </span>
            </div>
          </el-form-item>
            <el-form-item label="备注" prop="note" label-width="80px" label-position="left" class="item">
              <el-input type="textarea"
                        placeholder="请输入"
                        v-model="majorLangForm.note" maxlength="100"
                        show-word-limit></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
              type="primary" plain
              style="color: #000000;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
              @click="dialogTableVisible = false">取 消</el-button>
          <el-button type="primary" plain
                     style="color: #5556E5;padding: 2px 5px 2px 5px;height: 30px;border: 1px solid"
                     @click="dialogTableVisible = false">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "MajorLangTools",
  data(){
    return{
      //dialog中的表单
      majorLangForm:{
        name:'',
        lang1:'',
        lang2:'',
        type:'单语提取',
        note:'',
        count1:1,
        count2:2,
        count3:10
      },
      //控制dialog的显示与关闭
      dialogTableVisible:false,
      //审校工具表单
      toolsForm:{
        name:'',
        status:'',
      },
      //表单校验规则
      majorLangFormRules:{

      },
      //状态多选框
      statusOption:[
        {
          label:'已完成',
          value:'已完成'
        },
        {
          label:'进行中',
          value:'进行中'
        },
        {
          label:'未翻译',
          value:'未翻译'
        },
      ],
      //分页相关
      currentPage: 1,
      total: 20,
      size: 5,
      //表格数据
      tableData: [{
        id:'20210304',
        name:'西电简介',
        wordNum:'21',
        language:"中文-英文",
        status:'已完成',
        modifiedTime:'2021-11-16',
      },
      ]
    }
  },
  methods:{
    handleMore(){

    },
    //分页功能
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getVideoList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val
      this.getVideoList()
    },
  }
}
</script>

<style>
.item>.el-form-item__content{
  width: 300px;
}
.item2>.el-form-item__content{
  width: 150px;
}
.el-upload__tip{
  margin-top: 0;
}
.countClass>.el-input__inner{
  width: 60px;
  padding: 0 5px 0 5px;
}
.countClass>.el-form-item__content{
  width: 300px;
  margin-left: 0;
}
</style>